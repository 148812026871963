import gql from 'graphql-tag'

import {isUuid} from "../../utils/scripts"

const NotificacionModel = {
    create: (client, {id, mensaje, link, user_id, role_id, empresa_id, type, readed_at}, recursos) => client.mutate({
        mutation: gql`mutation createNotificacion($id: String, $mensaje: String!, $link: String, $user_id: String, $role_id: String, $empresa_id: String, $type: String!, $readed_at: String) {
            createNotificacion(id: $id, mensaje: $mensaje, link: $link, user_id: $user_id, role_id: $role_id, empresa_id: $empresa_id, type: $type, readed_at: $readed_at) {
                ${recursos}
            }
        }`,
        variables: {
            id: isUuid(id) ? id : null, mensaje, link: link !== '' ? link : null,
            user_id: isUuid(user_id) ? user_id : null, role_id: isUuid(role_id) ? role_id : null,
            empresa_id: isUuid(empresa_id) ? empresa_id : null, type, readed_at
        },
        fetchPolicy: 'no-cache'
    }),
    getNotificaciones: (client, recursos) => client.query({
        query: gql`{
            ListNotificaciones {
               ${recursos}             
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, update}, recursos) => client.mutate({
        mutation: gql`mutation updateNotificacion($id: String!, $update: JSON!) {
            updateNotificacion(id: $id, update: $update) {
                ${recursos}
            }
        }`,
        variables: {id, update},
        fetchPolicy: 'no-cache'
    }),
    getNotificacionesByEmpresa: (client, recursos) => client.query({
        query: gql`{
            notificacionesByEmpresa {
               ${recursos}             
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
            notificaciones {
               ${recursos}             
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteNotificacion($id: String!) {
            deleteNotificacion(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query notificacion($id: String!){
                notificacion(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {id},
        fetchPolicy: "no-cache"
    }),
    getByType: (client, type, recursos) => client.query({
        query: gql`query notificacionByType($type: String!) {
                notificacionByType(type: $type) {
                    ${recursos}
                }
            }`,
        variables: {type},
        fetchPolicy: "no-cache"
    })
}

export default NotificacionModel
