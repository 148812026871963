import EmpresaGQL from './Empresa'
import UserGQL from './User'
import ApiGQL from './Api'
import LocalGQL from './Local'
import PcgeGQL from './Pcge'
import TipoDocumentoGQL from './TipoDocumento'
import ClienteGQL from './Cliente'
import ProveedorGQL from './Proveedor'
import CentroCostoGQL from './CentroCosto'
import AmarreCentroCostoGQL from './AmarreCentroCosto'
import DetalleCentroCostoGQL from './DetalleCentroCosto'
import CompraGQL from './Compra'
import ModelGQL from './Model'
import OperacionGQL from './Operacion'
import CategoriaGQL from './Categoria'
import ProductoGQL from './Producto'
import PersonaGQL from './Persona'
import PagoGQL from './Pago'
import TipoComprobanteGQL from './TipoComprobante'
import TablaFacturacionGQL from './TablaFacturacion'
import TipoOperacionGQL from './TipoOperacion'
import CuentaFinancieraGQL from './CuentaFinanciera'
import VentaModel from './Venta'
import UbigeoModel from './Ubigeo'
import CpeModel from "./Cpe";
import GuiaModel from "./Guia"
import ZonaModel from "./Zona"
import MesaModel from "./Mesa"
import PosCajasModel from './PosCajas'
import CategoryModel from './Category'
import ServiceModel from './Service'
import PackModel from './Pack'
import AuthModel from './Auth'
import PedidoModel from './Pedido'
import SellerModel from "./Seller"
import FactureBuyModel from "./FactureBuy"
import PackFactureModel from "./PackFacture"
import ModuloModel from "./Modulo"
import PermissionModel from "./Permission"
import ReportesModel from "./Reportes"
import DetalleCajaModel from "./DetalleCaja"
import PayrollModel from "./Payroll"
import StorageModel from "./Storage"
import DiarioModel from "./Diario"
import CommissionModel from "./Commission"
import AsistenciaModel from "./Asistencia"
import PlameModel from "./Plame"
import CierreContableModel from "./CierreContable"
import HomeModel from "./Home"
import NotificacionModel from "./Notificacion"
import ReclamationModel from "./Reclamation"
import CursosModel from "./Cursos"
import ComentariosModel from "./Comentarios"
import CapitulosModel from "./Capitulos"
import DetalleCapituloVideoModel from "./DetalleCapituloVideo"
import RemOtrosEmpleadoresModel from "./RemOtrosEmpleadores"
import SalarioBeneficioModel from "./SalarioBeneficio"
import BuyModel from "./Buy"
import EmployeeModel from "./Employee"
import ContractModel from "./Contract"
import AsientoPredisenadoModel from "./AsientoPredisenado"
import MovimientoModel from "./Movimiento"
import ResponsableModel from "./Responsable"
import SireModel from "./Sire"
import CuotaModel from "./Cuota"
import ApiDriveModel from './ApiDrive'
import TipoAdjuntoModel from './TipoAdjunto'
import AdjuntoModel from './Adjunto'
import ConciliacionModel from './Conciliacion'
import PreciosCategoriaModel from './PreciosCategoria'
import PrecioProductoDetalleModel from './PrecioProductoDetalle'
import ControlInternoModel from './ControlInterno'
import DatabaseServerModel from './DatabaseServer'
import TributoModel from './Tributo'

export const Empresa = EmpresaGQL
export const User = UserGQL
export const Api = ApiGQL
export const Local = LocalGQL
export const Pcge = PcgeGQL
export const TipoDocumento = TipoDocumentoGQL
export const Cliente = ClienteGQL
export const Proveedor = ProveedorGQL
export const AmarreCentroCosto = AmarreCentroCostoGQL
export const DetalleCentroCosto = DetalleCentroCostoGQL
export const CentroCosto = CentroCostoGQL
export const Compra = CompraGQL
export const Model = ModelGQL
export const Operacion = OperacionGQL
export const Categoria = CategoriaGQL
export const Producto = ProductoGQL
export const Persona = PersonaGQL
export const Pago = PagoGQL
export const TipoComprobante = TipoComprobanteGQL
export const TablaFacturacion = TablaFacturacionGQL
export const TipoOperacion = TipoOperacionGQL
export const CuentaFinanciera = CuentaFinancieraGQL
export const Venta = VentaModel
export const Ubigeo = UbigeoModel
export const Cpe = CpeModel
export const Guia = GuiaModel
export const Zona = ZonaModel
export const Mesa = MesaModel
export const PosCajas = PosCajasModel
export const Auth = AuthModel
export const Category = CategoryModel
export const Service = ServiceModel
export const Pack = PackModel
export const Pedido = PedidoModel
export const Seller = SellerModel
export const FactureBuy = FactureBuyModel
export const Modulo = ModuloModel
export const PackFacture = PackFactureModel
export const Permission = PermissionModel
export const Reportes = ReportesModel
export const DetalleCaja = DetalleCajaModel
export const Payroll = PayrollModel
export const Storage = StorageModel
export const Diario = DiarioModel
export const Commission = CommissionModel
export const Asistencias = AsistenciaModel
export const Plame = PlameModel
export const CierreContable = CierreContableModel
export const Home = HomeModel
export const Notificacion = NotificacionModel
export const Reclamation = ReclamationModel
export const Comentario = ComentariosModel
export const Curso = CursosModel
export const Capitulos = CapitulosModel
export const DetalleCapituloVideo = DetalleCapituloVideoModel
export const RemOtrosEmpleadores = RemOtrosEmpleadoresModel
export const SalarioBeneficio = SalarioBeneficioModel
export const Buy = BuyModel
export const Employee = EmployeeModel
export const Contract = ContractModel
export const AsientoPredisenado = AsientoPredisenadoModel
export const Movimiento = MovimientoModel
export const Responsable = ResponsableModel
export const Sire = SireModel
export const Cuota = CuotaModel
export const ApiDrive = ApiDriveModel
export const TipoAdjunto = TipoAdjuntoModel
export const Adjunto = AdjuntoModel
export const Conciliacion = ConciliacionModel
export const PreciosCategoriaM = PreciosCategoriaModel
export const PrecioProductoDetalleM = PrecioProductoDetalleModel
export const ControlInterno = ControlInternoModel
export const DatabaseServer = DatabaseServerModel
export const Tributo = TributoModel
