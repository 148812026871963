import React, {Fragment, useEffect, useState} from 'react'

// Layout
import AppHeader from '../Layout/AppHeader/'
import AppSidebar from '../Layout/AppSidebar/'
import AppFooter from '../Layout/AppFooter/'
// Theme Options
import ThemeOptions from '../Layout/ThemeOptions/'
import {useAuth} from '../Context'

import Contable from "./Home/Contable"
import Facturacion from "./Home/Facturacion"
import Aplicativo from "./Home/Aplicativo"
import MainMultimedia from "./Multimedia/RepMultimedia/MainMultimedia"
import {decodeToken} from "../utils/scripts"

require('dotenv').config()

const Home = ({history}) => {
    const {auth} = useAuth()

    const role = decodeToken(auth.authentication).role_id
    const [head, setHead] = useState(true)

    useEffect(() => setHead(!(auth.modulo_id === '1fd2848e-b6b5-11ec-8c3d-18c04dc1e3d5' && role !== 'ea7e35be-220b-11ec-bdf8-13a4a75f3041')), [role, auth])

    const getModulo = () => {
        switch (auth.modulo_id) {
            case '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0':
                return <Contable/>
            case '24414f8c-1a66-11ec-9149-dfe7ef2f825e':
                return <Facturacion/>
            case '2b7132b8-1a66-11ec-9e9e-af7f46461ae7': // Planilla
                return <Facturacion/>
            case '33bb278a-1a66-11ec-9e9f-8b03442b4675':
                return <Aplicativo {...{history}}/>
            case '1fd2848e-b6b5-11ec-8c3d-18c04dc1e3d5':
                return <MainMultimedia {...{history}}/>
            default:
                return <></>
        }
    }

    return (
        <Fragment>
            <ThemeOptions/>
            <AppHeader/>
            <div className={head ? 'app-main' : 'app-main__outer p-0'}>
                {head ? <AppSidebar/> : <></>}
                <div className='app-main__outer'>
                    <div className='app-main__inner'>
                        {getModulo()}
                    </div>
                    <AppFooter/>
                </div>
            </div>
        </Fragment>
    )
}

export default Home
