import gql from 'graphql-tag'

const CentroCosto = {
    create: (client, {codigo, nombre, tipo}, recursos) => client.mutate({
        mutation: gql`mutation createCentroCosto($codigo: String!, $nombre: String!, $tipo: String) {
            createCentroCosto(codigo: $codigo, nombre: $nombre, tipo: $tipo) {
                ${recursos}
            }
        }`,
        variables: {codigo, nombre, tipo}
    }),
    getAll: (client, withEliminated, recursos) => client.query({
        query: gql`query centroCostos($withEliminated: Boolean!) {
            centroCostos(withEliminated: $withEliminated) {
                  ${recursos}
              }
          }`,
        variables: {withEliminated},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query centroCosto($id: String!) {
                centroCosto(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, codigo, nombre, tipo}, recursos) => client.mutate({
        mutation: gql`mutation updateCentroCosto($id: String!, $update: JSON!){
                  updateCentroCosto(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {id, update: {codigo, nombre, tipo}}
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCentroCosto($id: String!){
                  deleteCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarCentroCosto($id: String!){
                  activarCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    getByCodigo: (client, codigo, recursos) => client.query({
        query: gql`query  centroCostosByCodigo ($codigo: String!){
            centroCostosByCodigo(codigo: $codigo) 
            {${recursos}}
        }`,
        variables: {codigo},
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query pcgeByParam($param: String!){
                pcgeByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    createOrUpdateControlUnidades: (client, {producto_id, unidades}, recursos) => client.mutate({
        mutation: gql`mutation createOrUpdateControlUnidades($producto_id: String!, $unidades: JSON!) {
            createOrUpdateControlUnidades(producto_id: $producto_id, unidades: $unidades) {
                ${recursos}
            }
        }`,
        variables: {producto_id, unidades}
    }),
    controlUnidadesByYear: (client, {producto_id, periodo}, recursos) => client.query({
        query: gql`query controlUnidadesByYear($producto_id: String!, $periodo: String!) {
            controlUnidadesByYear(producto_id: $producto_id, periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {producto_id, periodo},
        fetchPolicy: 'no-cache'
    }),
    createOrUpdateInventarioProductos: (client, {producto_id, periodo, costos, inventarios, totales}, recursos) => client.mutate({
        mutation: gql`mutation createOrUpdateInventarioProductos($producto_id: String!, $periodo: String!, $costos: JSON!, $inventarios: JSON!, $totales: JSON!) {
            createOrUpdateInventarioProductos(producto_id: $producto_id, periodo: $periodo, costos: $costos, inventarios: $inventarios, totales: $totales) {
                ${recursos}
            }
        }`,
        variables: {producto_id, periodo, costos, inventarios, totales}
    }),
    inventarioProductosByPeriodo: (client, {producto_id, periodo}, recursos) => client.query({
        query: gql`query inventarioProductosByPeriodo($producto_id: String!, $periodo: String!) {
            inventarioProductosByPeriodo(producto_id: $producto_id, periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {producto_id, periodo},
        fetchPolicy: 'no-cache'
    }),
    calculoCcByPeriodo: (client, {periodo, reload}, recursos) => client.query({
        query: gql`query calculoCcByPeriodo($periodo: String!, $reload: Boolean) {
            calculoCcByPeriodo(periodo: $periodo, reload: $reload) {
                ${recursos}
            }
        }`,
        variables: {periodo, reload},
        fetchPolicy: 'no-cache'
    })
}

export default CentroCosto
