import React, {Fragment, useEffect, useState} from "react"
import {Col, Row, NavLink} from "reactstrap"

import Cursos from "../../../Components/Models/Cursos"
import {useAuth} from "../../../Context"
import FilterTable from "../../../Components/FilterTable"

const TabGeneral = ({id_modulo, history}) => {
    const {client} = useAuth()

    const [data, setData] = useState([])
    const [html, setHtml] = useState([])
    const [allData, setAllData] = useState([])
    const [buscador, setBuscador] = useState([])
    const [name, setName] = useState('d-none')

    useEffect(() => {
        if (!id_modulo) return
        Cursos
            .cursoModuloId(client, id_modulo, `data`)
            .then(response => {
                const {array_all, array_divide} = response.data.cursoModulo_id.data
                setData(array_divide)
                setAllData(array_all)
                setName('')
            })
    }, [id_modulo])

    useEffect(() => {
        if (buscador.length === 0) return
        let array_arrays = []
        for (let i = 0; i < buscador.length; i += 10)
            array_arrays.push(buscador.slice(i, i + 10))
        setData(array_arrays)
    }, [buscador])

    useEffect(() => {
        if (data.length <= 0) return
        let elementos = []
        let lef = 0, right = 0, all = 0
        for (const element of data) {
            lef++
            right++
            all++
            elementos = [...elementos,
                <Col md={3}
                     style={{
                         borderBottom: 'solid 1px #000000', borderTop: 'solid 1px #000000',
                         borderLeft: lef === 1 ? 'solid 1px #000000' : '',
                         borderRight: right === 4 || all === data.length ? 'solid 1px #000000' : ''
                     }}>
                    {
                        element.map(elemn => {
                            return elemn.to ?
                                <tr style={{marginLeft: '5px'}}>
                                    <div>
                                        <NavLink href="javascript:void(0);"
                                                 onClick={() => history.push(`${elemn.to}`)}>
                                            <i className="nav-link-icon pe-7s-film"/>
                                            <span>{`${elemn.id}. ${elemn.label}`}</span>
                                        </NavLink>
                                    </div>
                                </tr> : elemn.parentId ? <tr style={{marginLeft: '5px'}}>
                                        {`${elemn.id}. ${elemn.label}`}
                                    </tr> :
                                    <tr style={{marginLeft: '5px'}}>
                                        <strong>{`${elemn.id}. ${elemn.label}`}</strong>
                                    </tr>
                        })
                    }
                </Col>
            ]
            lef = lef === 4 ? 0 : lef
            right = right === 4 ? 0 : right
        }
        setHtml(elementos)
    }, [data])

    return (
        <Col>
            <Row style={{justifyContent: "center"}} className={name}>
                <Col md={4}>
                    <FilterTable {...{data: allData, setData: setBuscador, md: 12, msg: 'Escriba para Buscar'}}/>
                </Col>
            </Row>
            <br/>
            <Row>
                {html.length > 0 ? html : <></>}
            </Row>
        </Col>
    )
}
export default TabGeneral
