import gql from "graphql-tag"

const Asistencia = {
    createAsistencia: (client, id_employee, array, boolean, atipico = false) => client.mutate({
        mutation: gql`mutation createAsistencia(
                $id_employee: String
                $json: JSON
                $boolean: Boolean
                $atipico: Boolean
            ){
            createAsistencia(
                id_employee: $id_employee
                json: $json
                boolean: $boolean
                atipico: $atipico
            ){
                id_employee
            }
    }`,
        variables: {
            id_employee: id_employee,
            json: array,
            boolean: boolean,
            atipico: atipico,
        }
    }),
    selectReportAsistencia: (client, employee, periode1, periode, excel) => client.query({
        query: gql`query selectReportAsistencia(
                    $id_employee: String
                    $periode1: String
                    $periode2: String
                    $excel: Boolean
                ){
                selectReportAsistencia(
                    id_employee: $id_employee
                    periode1: $periode1
                    periode2: $periode2
                    excel: $excel
                ) {
                    reportjson
                }
            }`,
        variables: {
            id_employee: employee,
            periode1: periode1,
            periode2: periode,
            excel
        },
        fetchPolicy: "no-cache"
    }),
    deleteAsistencia: (client, send) => client.mutate({
        mutation: gql`mutation createRegAsistencia(
            $periode: String,
            $id_employee: String,
            $argsx: Boolean
        ){
            createRegAsistencia(
                periode: $periode
                id_employee: $id_employee
                argsx: $argsx
            ){
                id_employee
            }
        }`,
        variables: {
            periode: send.args1,
            id_employee: send.args11,
            argsx: send.argsx
        }
    }),
    excelImportAsistencia: (client, datos) => client.query({
        query: gql`query excelImportAsistencia(
            $datos: JSON,
        ){
            excelImportAsistencia(
                datos: $datos,
            ){
                reportjson
            }
        }`,
        variables: {
            datos: datos,
        },
        fetchPolicy: "no-cache"
    }),
    excelConceptos: (client, datos) => client.query({
        query: gql`query excelConceptos(
            $datos: JSON,
        ){
            excelConceptos(
                datos: $datos,
            ){
                reportjson
            }
        }`,
        variables: {
            datos: datos,
        },
        fetchPolicy: "no-cache"
    }),
    importAsistencia: (client, send, resource) => client.mutate({
        mutation: gql`mutation importAsistencia(
            $part1: JSON,
            $part2: JSON,
        ){
            importAsistencia(
                part1: $part1
                part2: $part2
            ){
                ${resource}
            }
        }`,
        variables: {
            part1: send.part1,
            part2: send.part2,
        }
    }),
    consultTrab: (client, send, resource) => client.mutate({
        mutation: gql`mutation consultTrab(
            $part1: JSON,
            $part2: JSON,
        ){
            consultTrab(
                part1: $part1
                part2: $part2
            ){
                ${resource}
            }
        }`,
        variables: {
            part1: send.part1,
            part2: send.part2,
        }
    }),
    importConcepto: (client, part1, part2, resource) => client.mutate({
        mutation: gql`mutation importConcepto(
            $part1: JSON,
            $part2: JSON,
        ){
            importConcepto(
                part1: $part1
                part2: $part2
            ){
                ${resource}
            }
        }`,
        variables: {
            part1: part1,
            part2: part2,
        }
    }),
    validateAsistencia: (client, {datos, horario_id}) => client.mutate({
        mutation: gql`mutation validarAsistencia($datos: JSON, $horario_id: String){
            validarAsistencia(datos: $datos, horario_id: $horario_id){
                    reportjson
            }
       }`,
        variables: {
            datos, horario_id
        },
        fetchPolicy: 'no-cache'

    }),
    validarHorarioDNI: (client, {dni, horario_id}) => client.mutate({
        mutation: gql`mutation validar_horarioDNI($dni: String, $horario_id: String){
            validar_horarioDNI(dni: $dni, horario_id: $horario_id){
                validate
            }    
       }`,
        variables: {
            dni, horario_id
        },
        fetchPolicy: 'no-cache'
    }),
    createRegAsistencia: (client, send) => client.mutate({
        mutation: gql`mutation createRegAsistencia(
                $periode: String,
                $days_calendar: Int, 
                $day_jobs: Float,
                $hour25: Float,
                $hour35: Float,
                $delay: Float,
                $foul: Int, 
                $day_delay: Int, 
                $exception: Int,
                $feriade: Int,
                $id_employee: String,
                $detalle_extras: JSON,
                $detalle_atipico: JSON
            ){
            createRegAsistencia(
                periode: $periode,
                days_calendar: $days_calendar,
                day_jobs: $day_jobs,
                hour25: $hour25,
                hour35: $hour35,
                delay: $delay,
                foul: $foul,
                day_delay: $day_delay,
                exception: $exception,
                feriade: $feriade,
                id_employee: $id_employee,
                detalle_extras: $detalle_extras
                detalle_atipico: $detalle_atipico
            ){
                id_employee
            }
    }`,
        variables: {
            periode: send.args1,
            days_calendar: send.args2,
            day_jobs: send.args3,
            hour25: send.args4,
            hour35: send.args5,
            delay: send.args6,
            foul: send.args7,
            day_delay: send.args8,
            exception: send.args9,
            feriade: send.args10,
            id_employee: send.args11,
            detalle_extras: send.detalle_extras,
            detalle_atipico: send.detalle_atipico
        }
    }),

    selectAsistencia: (client, id_employee, array, value = false) => client.query({
        query: gql`query selectAsistencia(
                $id_employee: String
                $day: String
                $value: Boolean
            ){
            selectAsistencia(
                id_employee: $id_employee
                day: $day
                value: $value
            ) {
                opcion, excception, eeind, einit, fend, finit, atipico, trab, slepp, inicio
            }
        }`,
        variables: {
            id_employee: id_employee,
            day: array,
            value: value
        },
        fetchPolicy: "no-cache"
    }),

    selectAllAsistencia: (client, id_employee, periodo) => client.query({
        query: gql`query selectAllAsistencia(
                $id_employee: String
                $periodo: String
            ){
            selectAllAsistencia(
                id_employee: $id_employee
                periodo: $periodo
            ) {
                id_employee, excception, days, eeind, einit, fend, finit, horas1, horas2, horas3, opcion, sale_vacaciones,
                feriado_descanso
            }
        }`,
        variables: {
            id_employee: id_employee,
            periodo: periodo
        },
        fetchPolicy: "no-cache"
    }),
    getAsistance: (client, {id_employee, periode}) => client.query({
        query: gql` query regAsistenciaSelect($periode: String, $id_employee: String){
            regAsistenciaSelect(id_employee: $id_employee, periode: $periode){
            reportjson
            }
        }`,
        variables: {
            id_employee, periode
        },
        fetchPolicy: "no-cache"
    }),

    selectRegAsistencia: (client, id_employee, periodo) => client.query({
        query: gql`query selectRegAsistencia(
                $id_employee: String
                $periode: String
            ){
            selectRegAsistencia(
                id_employee: $id_employee
                periode: $periode
            ) {
               days_calendar, day_jobs, hour25, hour35, delay, foul, day_delay, exception, feriade, detalle_extras,
               detalle_atipico
            }
        }`,
        variables: {
            id_employee: id_employee,
            periode: periodo
        },
        fetchPolicy: "no-cache"
    }),
    regAsistenciaAtipico: (client, {id_contract, periodo}) => client.query({
        query: gql`
            query regAsistenciaPrev($id_contract: String, $periodo: String){
              regAsistenciaPrev(id_contract: $id_contract, periodo: $periodo){
               detalle_extras
              } 
            }
        `,
        variables: {id_contract, periodo},
        fetchPolicy: "no-cache"
    }),
    guardarAsistenciaMasiva: (client, trabajadores) => client.mutate({
        mutation: gql`
         mutation saveAsistenciaMasiva($trabajadores: JSON){
            saveAsistenciaMasiva(trabajadores: $trabajadores){
                reportjson
            }
         }
        `,
        variables: {trabajadores},
        fetchPolicy: "no-cache",
        context: {timeout: 480000},
    })

}

export default Asistencia