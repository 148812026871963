import React, {Fragment, useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import MetisMenu from 'react-metismenu'

import {decodeToken} from "../../utils/scripts"
import {useAuth} from '../../Context'
import {
    HomeNav, AppNav, FacturacionNav, PlanillaNav, AdminNav, ContablesNav, PosNav, ReporteNav, UtilitariosNav,
    ReporteDivNav, MantenimientoNav, MovimientosNav, AplicativosNav, SolNav, PdtNav, XmlNav, LibyRegNav, LibElecNav,
    LibroEcocont, EstusFinanNav, OperPla, ReportesPla, ManFactura, UtilitariosPla, ReportDivNav, GeneradorPdfNav,
    SireNav, ConsultaSunatNav
} from './navItems'

Array.prototype.uniqueObject = function (element) {
    return [...new Set(this.map(item => item[element]))]
}

Array.prototype.orderByString = function () {
    return this.sort((a, b) => a.localeCompare(b))
}

Array.prototype.unique = function (a) {
    return function () {
        return this.filter(a)
    }
}(function (a, b, c) {
    return c.indexOf(a, b + 1) < 0
})

const Nav = () => {
        const {auth, company} = useAuth()

        const [data, setData] = useState([])
        const [Pos, setPos] = useState([])
        const multimedia_main = [{
            id: 'MM0001', icon: 'pe-7s-film', label: 'Admin. Multimedia', to: '/mm/main', category: 'MULTIMEDIA'
        }]
        const utilNav = UtilitariosNav.filter(i => i.id !== 'UTL001-9' && i.id !== 'UTL001-10')
        const reportDiv = ReporteDivNav.filter(i => i.id !== 'DIV001-14')

        useEffect(() => {
            if (auth) {
                let value = auth.authorization ? decodeToken(auth.authorization).permissions : []
                setData(value.map(item => item.id))
            }
            if (!company) return
            let rubro = company.rubro
            if (rubro === '312317a0-3c01-11ec-9bab-230c8243001c') {
                const Pos = PosNav.map(i => i.id === 'POS006' ? {...i, label: "Barra"} : i)
                setPos(Pos)
            }
            if (rubro === 'b4540182-4275-11ec-9f69-3f4be2201f55') {
                let Pos = PosNav.filter(i => i.id !== 'POS006')
                Pos = Pos.map(i => i.id === 'POS005' ? {...i, label: "Toma de Pedidos"} : i.id === 'POS002' ? {
                    ...i,
                    label: "Lados"
                } : i)
                setPos(Pos)
            }
            if (rubro === '29fb6d60-3c01-11ec-9bab-230c8243001c') {
                let Pos = PosNav.filter(i => i.id !== 'POS006')
                Pos = Pos.map(i => i.id === 'POS005' ? {...i, label: "Toma de reservas"} : i.id === 'POS002' ? {
                    ...i,
                    label: "Habitaciones"
                } : i)
                setPos(Pos)
            }
            if (rubro === '27ab89f0-3c01-11ec-9bab-230c8243001c')
                setPos(PosNav)
            if (rubro === '256172e0-3c01-11ec-9bab-230c8243001c' || rubro === '20eba230-3c01-11ec-9bab-230c8243001c' || rubro === 'fe57642c-3b58-11ed-a261-0242ac120002') {
                let Pos = PosNav.filter(i => i.id !== 'POS006' && i.id !== 'POS002')
                Pos = Pos.map(i => i.id === 'POS005' ? {...i, label: "Toma de Pedidos"} : i)
                setPos(Pos)
            }
        }, [auth, company])
        return (
            <Fragment>
                {
                    auth.modulo_id === '1fd2848e-b6b5-11ec-8c3d-18c04dc1e3d5' ? <></> :
                        <Fragment>
                            <MetisMenu content={HomeNav} activeLinkFromLocation className="vertical-nav-menu mt-2"
                                       iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                }
                {
                    auth.modulo_id !== '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>DATOS GENERALES</h5>
                            <MetisMenu content={AppNav} activeLinkFromLocation
                                       className="vertical-nav-menu mt-2"
                                       iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }

                {/*MENÚ CONTABLE (EXCLUSIVAMENTE)*/}
                {
                    myNav(MantenimientoNav.filter(item => item.id === 'MAN001-3' || item.id === 'MAN001-4'), data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <MetisMenu content={myNav(MantenimientoNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ContablesNav, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>ANOTACIÒN DE OPERACIONES</h5>
                            <MetisMenu content={myNav(ContablesNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ReporteNav.filter(i => i.id === 'REG001-4'), data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>HOJA DE TRABAJO</h5>
                            <MetisMenu
                                content={myNav(ReporteNav.filter(i => i.id === 'REG010'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(SireNav, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>SIRE SUNAT</h5>
                            <MetisMenu content={SireNav} activeLinkFromLocation className="vertical-nav-menu"
                                       iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ReporteNav.filter(i => i.id !== 'REG001-4'), data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>REPORTES CONTABLES</h5>
                            <MetisMenu
                                content={myNav(ReporteNav.filter(i => i.id !== 'REG010'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(LibroEcocont, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <MetisMenu content={myNav(LibroEcocont, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ReportDivNav, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <MetisMenu content={myNav(ReportDivNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(reportDiv, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>REPORTES DIVERSOS</h5>
                            <MetisMenu content={myNav(reportDiv, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(EstusFinanNav, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>ESTADOS FINANCIEROS</h5>
                            <MetisMenu content={myNav(EstusFinanNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ConsultaSunatNav, data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>CONSULTAS SUNAT</h5>
                            <MetisMenu content={myNav(ConsultaSunatNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(utilNav.filter(i => i.id !== 'UTL001-1' && i.id !== 'UTL001-10' && i.id !== 'UTL001-9' && i.id !== 'UTL001-2' && i.id !== 'UTL001-3'), data).length > 0 && auth.modulo_id === '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>UTILITARIOS</h5>
                            <MetisMenu
                                content={myNav(utilNav.filter(i => i.id !== 'UTL001-1' && i.id !== 'UTL001-10' && i.id !== 'UTL001-9' && i.id !== 'UTL001-2' && i.id !== 'UTL001-3'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }

                {/*MENÚ FACTURACIÓN (EXCLUSIVAMENTE)*/}
                {
                    myNav(ManFactura, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            {/*<h5 className={`app-sidebar__heading`}>MANTENIMIENTO</h5>*/}
                            <MetisMenu content={myNav(ManFactura, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(MovimientosNav, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>MOVIMIENTOS DE INVENTARIOS</h5>
                            <MetisMenu content={myNav(MovimientosNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(FacturacionNav, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>FACTURACIÓN ELECTRÓNICA</h5>
                            <MetisMenu content={myNav(FacturacionNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ContablesNav, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>CONTABLE</h5>
                            <MetisMenu content={myNav(ContablesNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    Pos.length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>POS</h5>
                            <MetisMenu content={myNav(Pos, data).sort((a, b) => a.nro - b.nro)} activeLinkFromLocation
                                       className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ReporteNav, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>REPORTES CONTABLES</h5>
                            <MetisMenu content={myNav(ReporteNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(LibroEcocont.filter(i => i.id !== 'ECO001-7' || 'ECO001-7-1' || 'ECO001-7-2' || 'ECO001-8' || 'ECO001-8-1' || 'ECO001-8-1' || 'ECO001-9'), data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            {/*<h5 className={`app-sidebar__heading`}>LIBROS ELECTRÒNICOS</h5>*/}
                            <MetisMenu
                                content={myNav(LibroEcocont.filter(i => i.id !== 'ECO001-7' || 'ECO001-7-1' || 'ECO001-7-2' || 'ECO001-8' || 'ECO001-8-1' || 'ECO001-8-1' || 'ECO001-9'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(reportDiv, data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            {/*<h5 className={`app-sidebar__heading`}>REPORTES DIVERSOS</h5>*/}
                            <MetisMenu content={myNav(reportDiv, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(utilNav.filter(i => i.id !== 'UTL002' && i.id !== 'UTL002-1' && i.id !== 'UTL002-2' && i.id !== 'UTL002-3'), data).length > 0 && auth.modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>UTILITARIOS</h5>
                            <MetisMenu
                                content={myNav(utilNav.filter(i => i.id !== 'UTL002' && i.id !== 'UTL002-1' && i.id !== 'UTL002-2' && i.id !== 'UTL002-3'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }

                {/*MENÚ PLANILLAS (EXCLUSIVAMENTE)*/}
                {
                    myNav(PlanillaNav, data).length > 0 && auth.modulo_id === '2b7132b8-1a66-11ec-9e9e-af7f46461ae7' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>MANTENIMIENTO</h5>
                            <MetisMenu content={myNav(PlanillaNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(OperPla, data).length > 0 && auth.modulo_id === '2b7132b8-1a66-11ec-9e9e-af7f46461ae7' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>PLANILLA MENSUAL</h5>
                            <MetisMenu content={myNav(OperPla, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(ReportesPla, data).length > 0 && auth.modulo_id === '2b7132b8-1a66-11ec-9e9e-af7f46461ae7' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>REPORTES</h5>
                            <MetisMenu content={myNav(ReportesPla, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(UtilitariosPla, data).length > 0 && auth.modulo_id === '2b7132b8-1a66-11ec-9e9e-af7f46461ae7' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>UTILITARIOS</h5>
                            <MetisMenu content={myNav(UtilitariosPla, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }

                {/*MENÚ APLICATIVOS (EXCLUSIVAMENTE)*/}
                {
                    myNav(AplicativosNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>CONSULTAS ONLINE</h5>
                            <MetisMenu
                                content={myNav(AplicativosNav.filter(i => i.id !== 'APP001-4'), data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(SolNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>PROCESSOS SOL</h5>
                            <MetisMenu content={myNav(SolNav, data).sort((a, b) => a.nro - b.nro)} activeLinkFromLocation
                                       className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(PdtNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>PDT'S</h5>
                            <MetisMenu content={myNav(PdtNav, data).sort((a, b) => a.nro - b.nro)} activeLinkFromLocation
                                       className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(XmlNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>IMPORTACIÓN MASIVA XML</h5>
                            <MetisMenu content={myNav(XmlNav, data).sort((a, b) => a.nro - b.nro)} activeLinkFromLocation
                                       className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(GeneradorPdfNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>GENERADOR PDF</h5>
                            <MetisMenu
                                content={myNav(GeneradorPdfNav, data).sort((a, b) => a.nro - b.nro)}
                                activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(LibyRegNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>LIBROS Y REGSTROS CONTABLES</h5>
                            <MetisMenu content={myNav(LibyRegNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    myNav(LibElecNav, data).length > 0 && auth.modulo_id === '33bb278a-1a66-11ec-9e9f-8b03442b4675' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>LIBROS ELECTRÓNICOS (SLE-PLE 5.2)</h5>
                            <MetisMenu content={myNav(LibElecNav, data).sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
                {
                    multimedia_main.length > 0 && auth.modulo_id === "1fd2848e-b6b5-11ec-8c3d-18c04dc1e3d5" && decodeToken(auth.authorization).role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041' ?
                        (<Fragment>
                            <h5 className={`app-sidebar__heading`}>MULTIMEDIA</h5>
                            <MetisMenu content={multimedia_main.sort((a, b) => a.nro - b.nro)}
                                       activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                       classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>) : <></>
                }
                {
                    decodeToken(auth.authorization).role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041' ? (
                        <Fragment>
                            <h5 className={`app-sidebar__heading`}>ADMINISTRADOR</h5>
                            <MetisMenu content={AdminNav} activeLinkFromLocation className="vertical-nav-menu"
                                       iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                        </Fragment>
                    ) : (<></>)
                }
            </Fragment>
        )
    },
    getId = (ids, id) => {
        ids.push(id)
        id.lastIndexOf('-')
        const found = id.lastIndexOf('-')
        if (found > -1)
            getId(ids, id.substring(0, found))

        return ids
    },
    myNav = (navs, myServices) => {
        let myIds = []
        for (const myId of myServices) {
            const newNav = navs.find(({id}) => myId === id)
            if (newNav) myIds.push(newNav)
        }
        myIds = myIds.uniqueObject('id')
        let ids = []
        for (const id of myIds)
            ids = ids.concat(getId([], id))
        ids = ids.unique()
        // ids.orderByString()
        const myNavs = []
        for (const id2 of ids) {
            const newNav = navs.find(({id}) => id2 === id)
            if (newNav) myNavs.push(newNav)
        }
        return myNavs
    }

export default withRouter(Nav)
