import gql from "graphql-tag"

const ApiDrive = {
    // Queries
    allFiles: (client, recursos) => {
        return client.query({
            query: gql`query allFiles{
                allFiles {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    fileById: (client, id, recursos) => {
        return client.query({
            query: gql`query fileById($id: String!){
                fileById(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: "no-cache"
        })
    },
    filesByEmpresaId: (client, empresa_id, recursos) => {
        return client.query({
            query: gql`query filesByEmpresaId($empresa_id: String!){
                filesByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            variables: {empresa_id},
            fetchPolicy: "no-cache"
        })
    },
    fileByName: (client, file_name, recursos) => {
        return client.query({
            query: gql`query fileByName($file_name: String!){
                fileByName(file_name: $file_name) {
                    ${recursos}
                }
            }`,
            variables: {file_name},
            fetchPolicy: "no-cache"
        })
    },
    getEmpresasProduccionDrive: (client, recursos) => {
        return client.query({
            query: gql`query getEmpresasProduccionDrive{
                getEmpresasProduccionDrive {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    addFile: (client, {empresa_id, external_id}, recursos) => {
        return client.mutate({
            mutation: gql`mutation addFile($empresa_id: String!, $external_id: String!){
                addFile(empresa_id: $empresa_id, external_id: $external_id) {
                    ${recursos}
                }
            }`,
            variables: {empresa_id, external_id}
        })
    },
    updateFile: (client, {id, update}, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateFile($id: String!, $update: JSON!){
                updateFile(id: $id, update: $update) {
                    ${recursos}
                }
            }`,
            variables: {id, update}
        })
    },
    deleteFile: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteFile($id: String!){
                deleteFile(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    restoreFile: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation restoreFile($id: String!){
                restoreFile(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    createFoldersEmpresas: (client, empresas, recursos) => {
        return client.mutate({
            mutation: gql`mutation createFoldersEmpresas($empresas: JSON!){
                createFoldersEmpresas(empresas: $empresas) {
                    ${recursos}
                }
            }`,
            variables: {empresas}
        })
    }
}

export default ApiDrive