import React, {useEffect} from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import Tabs from "react-responsive-tabs"
import {faPhotoVideo} from "@fortawesome/free-solid-svg-icons"
import {Card, CardBody, Container} from "reactstrap"

import PageTitle from "../../../Layout/AppMain/PageTitle"
import {useAuth} from "../../../Context"
import TabGeneral from "./TabGeneral"

const MainMultimedia = ({history}) => {
    const {toast, removeAllToasts} = useAuth()

    let tabsContent = [
        {
            title: 'Contable',
            content: <TabGeneral{...{id_modulo: '1a625d3a-1a66-11ec-85b0-e3d8588dcbc0', history}}/>
        },
        {
            title: 'Facturación',
            content: <TabGeneral{...{id_modulo: '24414f8c-1a66-11ec-9149-dfe7ef2f825e', history}}/>
        },
        {
            title: 'Planillas',
            content: <TabGeneral{...{id_modulo: '2b7132b8-1a66-11ec-9e9e-af7f46461ae7', history}}/>
        },
        {
            title: 'Aplicativos',
            content: <TabGeneral{...{id_modulo: '33bb278a-1a66-11ec-9e9f-8b03442b4675', history}}/>
        },
        {
            title: 'Webinars',
            content: <TabGeneral{...{id_modulo: '6771889c-f595-11ed-a2a0-000c2901d02f', history}}/>
        }
    ]

    useEffect(() => {
        removeAllToasts()
        toast.success('Bienvenido al aula virtual', {autoClose: 4500})
    }, [])

    const getTabs = () => tabsContent.map(({title, content}, key) => ({title, getContent: () => content, key}))

    return (
        <ReactCSSTransitionGroup component='div' transitionName='TabsAnimation' transitionAppear={true}
                                 transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <PageTitle heading='Multimedia' icon={faPhotoVideo} menus={[{to: '/mm/main', name: 'Multimedia'}]}/>
            <Container fluid className="p-0">
                <Card className="main-card">
                    <CardBody>
                        <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false}
                              showInkBar={true} items={getTabs()}/>
                    </CardBody>
                </Card>
            </Container>
        </ReactCSSTransitionGroup>
    )
}

export default MainMultimedia
