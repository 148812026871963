import gql from 'graphql-tag'


const CategoriaModel = {
    create: (client, {codigo, nombre}, recursos) => client.mutate({
        mutation: gql`mutation createCategoria(
                        $codigo: String!,
                        $nombre: String!,                       
                    ){
                    createCategoria(
                    codigo: $codigo,
                    nombre: $nombre
                    ){
                    ${recursos}
                    }
                    }`,
        variables: {
            codigo,
            nombre,
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
              categorias {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) =>
        client.query({
            query: gql`query categoria($id:String!){
        categoria(id: $id){
                   ${recursos}
                }
        }`,
            variables: {
                id
            },
            fetchPolicy: 'no-cache'
        }),
    update: (client, {id, nombre, codigo, local_id}, recursos) => client.mutate({
        mutation: gql`mutation updateCategoria($id: String!, $update: JSON!){
                updateCategoria(id: $id, update: $update){
                ${recursos}
                }
            }`,
        variables: {
            id,
            update: {
                nombre, local_id, codigo
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCategoria($id: String!){
        deleteCategoria(id: $id){
            ${recursos}
             }
        }`,
        variables: {
            id: id
        }
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateCategoria($id: String!){
        activateCategoria(id: $id){
            ${recursos}
             }
        }`,
        variables: {
            id: id
        }
    }),
    validarCategorias: (client, categorias, recursos) => client.query({
        query: gql`query validarCategorias($categorias: JSON!){
            validarCategorias(categorias: $categorias){
            ${recursos}
            }
        }`,
        variables: {categorias},
        fetchPolicy: 'no-cache'
    }),
    categoriasByCodigo: (client, codigo, recursos) => client.query({
        query: gql`query categoriasByCodigo($codigo: String){
        categoriasByCodigo(codigo: $codigo){
        ${recursos}
        }
        }`,
        variables: {codigo},
        fetchPolicy: 'no-cache'
    }),
    createCategoriasMasivos: (client, categorias, recursos) => client.mutate({
        mutation: gql`mutation categoriaMasivo($categorias: JSON!){
                  categoriaMasivo(categorias: $categorias) {
                    ${recursos}
                  }
                }`,
        variables: {categorias},
        fetchPolicy: 'no-cache'
    }),
    excelCategorias: (client, recursos) => client.query({
        query: gql`{
              excelCategorias {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),


}
export default CategoriaModel