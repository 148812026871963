import gql from 'graphql-tag'

const PcgeModel = {
    create: (client, {
        empresa_id, codigo, denominacion, tipo, tipo_id, amarres, esf, ern, erf, efle, ecp, efe, smvn, smve, cierre_id
    }, recursos) => client.mutate({
        mutation: gql`mutation createPcge($codigo: String!, $denominacion: String!, $tipo: String!, $tipo_id: String, $amarres: JSON!, $esf: Boolean, $ern: Boolean, $erf: Boolean, $efle: Boolean, $ecp: Boolean, $efe: String, $smvn: String, $smve: String, $cierre_id: String) {
            createPcge(codigo: $codigo, denominacion: $denominacion, tipo: $tipo, tipo_id: $tipo_id, amarres: $amarres, esf: $esf, ern: $ern, erf: $erf, efle: $efle, ecp: $ecp, efe: $efe, smvn: $smvn, smve: $smve, cierre_id: $cierre_id) {
                ${recursos}
            }
        }`,
        variables: {codigo, denominacion, amarres, tipo, tipo_id, esf, ern, erf, efle, ecp, efe, smvn, smve, cierre_id}
    }),
    getAll: (client, {empresa_id, withEliminated, limite}, recursos) => client.query({
        query: gql`query pcges($empresa_id: String, $withEliminated: Boolean!, $limite: JSON) {
            pcges(empresa_id: $empresa_id, withEliminated: $withEliminated, limite: $limite) {
                  ${recursos}
              }
          }`,
        variables: {empresa_id, withEliminated, limite},
        fetchPolicy: 'no-cache'
    }),
    countPCGES: (client, withEliminated) => client.query({
        query: gql`query countPCGE($withEliminated: Boolean!) {
            countPCGE(withEliminated: $withEliminated) {
                  value
              }
          }`,
        variables: {withEliminated},
        fetchPolicy: 'no-cache'
    }),
    pcgeBYParam: (client, {withEliminated, param}, recursos) => client.query({
        query: gql`query PCGEbyParam($withEliminated: Boolean!, $param: String!) {
            PCGEbyParam(withEliminated: $withEliminated, param:$param) {
                  ${recursos}
              }
          }`,
        variables: {withEliminated, param},
        fetchPolicy: 'no-cache'
    }),
    getByCodigo: (client, codigo, recursos) => client.query({
        query: gql`query  pcgeByCodigo ($codigo: String!){
        pcgeByCodigo(codigo: $codigo) 
            {${recursos}}
            }`,
        variables: {codigo},
        fetchPolicy: 'no-cache'
    }),
    getCcDefaults: (client, {empresa_id}, recursos) => client.query({
        query: gql`query ccDefaults($empresa_id: String!) {
            ccDefaults(empresa_id: $empresa_id) {
                  ${recursos}
              }
          }`,
        variables: {empresa_id},
        fetchPolicy: 'no-cache'
    }),
    getCcPersonalizado: (client, {empresa_id}, recursos) => client.query({
        query: gql`query ccPersonalizado($empresa_id: String!) {
            ccPersonalizado(empresa_id: $empresa_id) {
                  ${recursos}
              }
          }`,
        variables: {empresa_id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getPcge($id: String!) {
                pcge(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query pcgeByParam($param: String!){
                pcgeByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    asientoPcge: (client, provisiones, recursos) => client.query({
        query: gql`query asientoPcge($provisiones: [JSON]!) {
            asientoPcge(provisiones: $provisiones) {
                  ${recursos}
              }
          }`,
        variables: {provisiones},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, update, amarres}, recursos) => client.mutate({
        mutation: gql`mutation updatePcge($id: String!, $update: JSON!, $amarres: JSON!){
                  updatePcge(id: $id, update: $update, amarres: $amarres) {
                      ${recursos}
                    }
                  }`,
        variables: {id, update, amarres},
        context: {timeout: 960000}
    }),
    deleted: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedPcge($id: String!){
                  deletedPcge(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarPcge($id: String!){
                  activarPcge(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    createNew: (client, {empresa_id, plan}, recursos) => client.mutate({
        mutation: gql`mutation createNew($empresa_id: String!, $plan: String!) {
            createNew(empresa_id: $empresa_id, plan: $plan) {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        variables: {empresa_id, plan}
    }),
    getByCodigoPrev: (client, codigo, recursos) => client.query({
        query: gql`query  pcgeByCodigoPrev ($codigo: String!){
            pcgeByCodigoPrev(codigo: $codigo) 
            {${recursos}}
            }`,
        variables: {codigo},
        fetchPolicy: 'no-cache'
    }),
    createDetalle: (client, empresa_id, recursos) => client.mutate({
        mutation: gql`mutation createDetalle($empresa_id: String!) {
            createDetalle(empresa_id: $empresa_id) {
                ${recursos}
            }
        }`,
        variables: {empresa_id},
        context: {timeout: 960000}
    }),
    createTemp: (client, recursos) => client.mutate({
        mutation: gql`mutation  {
            createTemp {
                ${recursos}
            }
        }`,
        context: {timeout: 960000}
    })
}

export default PcgeModel
