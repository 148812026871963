import gql from "graphql-tag"

const DatabaseServer = {
    // Queries
    allDatabaseServers: (client, recursos) => {
        return client.query({
            query: gql`query allDatabaseServers{
                allDatabaseServers {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    databaseServerById: (client, id, recursos) => {
        return client.query({
            query: gql`query databaseServerById($id: String!){
                databaseServerById(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: "no-cache"
        })
    },
    databaseServerByNameOrIp: (client, name, ip_address, recursos) => {
        return client.query({
            query: gql`query databaseServerByNameOrIp($name: String!, $ip_address: String!){
                databaseServerByNameOrIp(name: $name, ip_address: $ip_address) {
                    ${recursos}
                }
            }`,
            variables: {name, ip_address},
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    addDatabaseServer: (client, {name, ip_address, description, type, status}, recursos) => {
        return client.mutate({
            mutation: gql`mutation addDatabaseServer($name: String!, $ip_address: String!, $description: String!, $type: Int!, $status: Int!){
                addDatabaseServer(name: $name, ip_address: $ip_address, description: $description, type: $type, status: $status) {
                    ${recursos}
                }
            }`,
            variables: {name, ip_address, description, type, status}
        })
    },
    updateDatabaseServer: (client, {id, update}, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateDatabaseServer($id: String!, $update: JSON!){
                updateDatabaseServer(id: $id, update: $update) {
                    ${recursos}
                }
            }`,
            variables: {id, update}
        })
    },
    deleteDatabaseServer: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteDatabaseServer($id: String!){
                deleteDatabaseServer(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    restoreDatabaseServer: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation restoreDatabaseServer($id: String!){
                restoreDatabaseServer(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    }
}

export default DatabaseServer