import gql from "graphql-tag"

const Adjunto = {
    // Queries
    allAdjuntos: (client, withEliminated, recursos) => {
        return client.query({
            query: gql`query allAdjuntos($withEliminated: Boolean!){
                allAdjuntos(withEliminated: $withEliminated) {
                    ${recursos}
                }
            }`,
            variables: {withEliminated},
            fetchPolicy: "no-cache"
        })
    },
    adjuntoById: (client, id, recursos) => {
        return client.query({
            query: gql`query adjuntoById($id: String!){
                adjuntoById(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: "no-cache"
        })
    },
    adjuntosByOperacionId: (client, operacion_id, recursos) => {
        return client.query({
            query: gql`query adjuntosByOperacionId($operacion_id: String!){
                adjuntosByOperacionId(operacion_id: $operacion_id) {
                    ${recursos}
                }
            }`,
            variables: {operacion_id},
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    addAdjunto: (client, {operacion_id, tipo_adjunto_id, file_name}, recursos) => {
        return client.mutate({
            mutation: gql`mutation addAdjunto($operacion_id: String!, $tipo_adjunto_id: String!, $file_name: String!){
                addAdjunto(operacion_id: $operacion_id, tipo_adjunto_id: $tipo_adjunto_id, file_name: $file_name) {
                    ${recursos}
                }
            }`,
            variables: {operacion_id, tipo_adjunto_id, file_name}
        })
    },
    deleteAdjunto: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAdjunto($id: String!){
                deleteAdjunto(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    }
}

export default Adjunto