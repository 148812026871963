import gql from "graphql-tag"

const Buy = {
    getAllBuy: (client, modulo_id, recursos) => client.query({
        query: gql`query getAllBuy ($modulo_id: String!){
            getAllBuy (modulo_id: $modulo_id){
                ${recursos}
            }
        }`,
        variables: {modulo_id},
        fetchPolicy: "no-cache"
    }),
    createOrder: (client, data, recursos) => client.mutate({
        mutation: gql`mutation createOrder(
                $name:  String!
                $email: String!
                $address: String!
                $doc: String!
                $phone: String
                $tipo: String!
                $mount: Int!
                $voucher: String
                $note: String
                $servicio: String
        ){
            createOrder(
                name: $name,
                email: $email,
                address: $address,
                doc: $doc,
                phone: $phone,
                tipo: $tipo,
                mount: $mount,
                voucher: $voucher,
                note: $note,
                servicio: $servicio,
            ) {
                ${recursos}
            }
        }`,
        variables: {
            name : data.name,
            email : data.email,
            address : data.address,
            doc : data.doc,
            phone : data.phone,
            tipo : data.tipo,
            mount : data.mount,
            voucher : data.voucher,
            note : data.note,
            servicio : data.servicio
        },
        fetchPolicy: "no-cache",
    }),
}

export default Buy