import gql from "graphql-tag"

const Contract = {
    createContract: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation createContract(
            $trabajador_id:    String,
            $cuspp:            String,
            $pensionary:       String
        ){
            createContract(
                trabajador_id: $trabajador_id,
                cuspp:         $cuspp,
                pensionary:    $pensionary
            ){
                ${recursos}
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id,
            cuspp: send.cuss,
            pensionary: send.pensionary
        }
    }),
    listBank: (client) => client.query({
        query: gql`query {
            listBank{
                code, description
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    filterBanco: (client, {send}, recursos) => client.query({
        query: gql`query filterContract(
            $trabajador_id:    String
        ){
            filterContract(
                trabajador_id:    $trabajador_id
            ){
                listBancos{
                    code
                    description
                }
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id
        }
    }),
    filterContractOther: (client, {send}, recursos) => client.query({
        query: gql`query filterContractOther(
            $trabajador_id: String
        ){
            filterContractOther(
                trabajador_id:  $trabajador_id
            ) {
                ${recursos}
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id
        },
        fetchPolicy: "no-cache"
    }),
    editOther: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation editOther(
            $trabajador_id:    String,
            $jornada:          String,
            $situacion:        String,
            $banco1:           String,
            $cta1:             String,
            $coin1:            String,
            $banco2:           String,
            $cta2:             String,
            $coin2:            String,
            $desj:             String,
            $deso:             String,
            $bonificacion: Boolean, 
            $monto_bonificacion: Float
            
        ){
            editOther(
                trabajador_id:    $trabajador_id,
                jornada:          $jornada,
                situacion:        $situacion,
                banco1:           $banco1,
                cta1:             $cta1,
                coin1:            $coin1,
                banco2:           $banco2,
                cta2:             $cta2,
                coin2:            $coin2,
                desj:             $desj,
                deso:             $deso,
                bonificacion: $bonificacion, 
                monto_bonificacion:$monto_bonificacion
            ){
                ${recursos}
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id,
            jornada: send.selectf,
            situacion: send.selectl,
            banco1: send.bank1,
            cta1: send.cta1,
            coin1: send.coin1,
            banco2: send.bank2,
            cta2: send.cta2,
            coin2: send.coin2,
            desj: send.des1,
            deso: send.des2,
            bonificacion: send.bonificacion,
            monto_bonificacion: Number(send.monto_bonificacion)
        }
    }),
    editContract: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation editContract(
        $trabajador_id:      String,
        $office:             String,
        $type:               String,
        $finit:              String,
        $fend:               String,
        $horario_id:         String,
        $typerenumeration:   String,
        $salary:             Float,
        $planilla_id:        String
        $pensionary:         String,
        $cuspp:              String,
        $commision:          String,
        $remuneration:       Float,
        $retention:          Float,
        $healthy:            String,
        $sctr:               Boolean,
        $family:             Boolean,
        $prima:              Float,
        $sctr1:              Boolean,
        $sctr2:              Boolean,
        $sctr3:              Boolean,
        $sctr4:              Boolean,
        $cona:               Boolean,
        $vida:               Boolean,
        $senati:             Boolean,
        $categoria:          String,
        $movilidad:          Boolean,
        $asignacion_escolar: Boolean,
        $a_voluntaria_trabajador:   Boolean,
        $a_voluntaria_empleador:    Boolean,
        $desc_sindical:             Boolean,
        $bae:                       Boolean,
        $bonificacion_altura:       Boolean,
        $bonificacion_altitud:      Boolean,
        $cota_cero:                 Boolean,
        $contacto_aguas_servidas:   Boolean,
        $asignacion_defuncion:      Boolean,
        $sencico:                   Boolean,
        $p_bae:                     Float
        ){
            editContract(
                trabajador_id:      $trabajador_id,
                office:             $office,
                type:               $type,
                finit:              $finit,
                fend:               $fend,
                horario_id:         $horario_id,
                typerenumeration:   $typerenumeration,
                salary:             $salary,
                pensionary:         $pensionary,
                planilla_id:        $planilla_id,
                cuspp:              $cuspp,
                commision:          $commision,
                remuneration:       $remuneration,
                retention:          $retention,
                healthy:            $healthy,
                sctr:               $sctr,
                family:             $family,
                prima:              $prima,
                sctr1:              $sctr1,
                sctr2:              $sctr2,
                sctr3:              $sctr3,
                sctr4:              $sctr4,
                cona:               $cona,
                vida:               $vida,
                senati:             $senati,
                categoria:          $categoria,
                movilidad:          $movilidad,
                asignacion_escolar:          $asignacion_escolar,
                a_voluntaria_trabajador:     $a_voluntaria_trabajador,
                a_voluntaria_empleador:      $a_voluntaria_empleador,
                desc_sindical:               $desc_sindical,
                bae:                         $bae,
                bonificacion_altura:         $bonificacion_altura,
                bonificacion_altitud:        $bonificacion_altitud,
                cota_cero:                   $cota_cero,
                contacto_aguas_servidas:     $contacto_aguas_servidas,
                asignacion_defuncion:        $asignacion_defuncion,
                sencico:                     $sencico,
                p_bae:                       $p_bae
            ){
                ${recursos}
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id,
            office: send.office,
            type: send.contract,
            finit: send.finicio,
            fend: send.ffin,
            horario_id: send.horario,
            typerenumeration: send.typeremuneration,
            planilla_id: send.planilla,
            salary: parseFloat(send.salary),
            pensionary: send.pensionary,
            cuspp: send.cuss,
            commision: send.commision,
            remuneration: parseFloat(send.remuneration),
            retention: parseFloat(send.retention),
            healthy: send.healt,
            sctr: Boolean(send.sctr),
            family: Boolean(send.family),
            prima: Number.parseFloat(send.prima),
            sctr1: send.sctr1,
            sctr2: send.sctr2,
            sctr3: send.sctr3,
            sctr4: send.sctr4,
            cona: send.cona,
            vida: send.vida,
            senati: send.senati,
            categoria: send.categoria !== '' ? send.categoria : null,
            movilidad: send.movilidad,
            asignacion_escolar: send.asignacion_escolar,
            a_voluntaria_trabajador: send.a_voluntaria_trabajador,
            a_voluntaria_empleador: send.a_voluntaria_empleador,
            desc_sindical: send.desc_sindical,
            bae: send.bae,
            bonificacion_altura: send.bonificacion_altura,
            bonificacion_altitud: send.bonificacion_altitud,
            cota_cero: send.cota_cero,
            contacto_aguas_servidas: send.contacto_aguas_servidas,
            asignacion_defuncion: send.asignacion_defuncion,
            sencico: send.sencico,
            p_bae: Number(send.p_bae)
        }
    }),
    filterContract: (client, {send}, recursos) => client.query({
        query: gql`query filterContract(
            $trabajador_id: String
        ){
            filterContract(
                trabajador_id:  $trabajador_id
            ) {
                ${recursos}
                listTypecontract{
                    code
                    abreviada
                }
                listPensionary{
                    code
                    abreviada
                }
                listHealty{
                    code
                    abreviada
                }
                listHorario(
                    empresa_id: "${send.empresa_id}"
                ){
                    id
                    nombre
                    atipico
                }
                listPlanilla(
                    empresa_id: "${send.empresa_id}"
                ){
                    id
                    nombre
                    regimen
                }
            }
        }`,
        variables: {
            trabajador_id: send.trabajador_id
        },
        fetchPolicy: "no-cache"
    }),
    updateContract: (client, contrato, recursos) => client.mutate({
        mutation: gql`mutation updateContrato($id: String!, $update: JSON!){
                  updateContrato(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: String(contrato.id),
            update: {
                ...contrato
            }
        }
    })
}

export default Contract
