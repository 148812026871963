import gql from "graphql-tag"

const TipoAdjunto = {
    // Queries
    allTipoAdjunto: (client, withEliminated, recursos) => {
        return client.query({
            query: gql`query allTipoAdjunto($withEliminated: Boolean!){
                allTipoAdjunto(withEliminated: $withEliminated) {
                    ${recursos}
                }
            }`,
            variables: {withEliminated},
            fetchPolicy: "no-cache"
        })
    },
    tipoAdjuntoById: (client, id, recursos) => {
        return client.query({
            query: gql`query tipoAdjuntoById($id: String!){
                tipoAdjuntoById(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: "no-cache"
        })
    },
    tipoAdjuntoByCodigo: (client, codigo, recursos) => {
        return client.query({
            query: gql`query tipoAdjuntoByCodigo($codigo: String!){
                tipoAdjuntoByCodigo(codigo: $codigo) {
                    ${recursos}
                }
            }`,
            variables: {codigo},
            fetchPolicy: "no-cache"
        })
    },
    tiposAdjuntoByEmpresa: (client, recursos) => {
        return client.query({
            query: gql`query tiposAdjuntoByEmpresa {
                tiposAdjuntoByEmpresa {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    addTipoAdjunto: (client, {codigo, descripcion, tipo_operacion}, recursos) => {
        return client.mutate({
            mutation: gql`mutation addTipoAdjunto($codigo: String!, $descripcion: String!, $tipo_operacion: JSON!){
                addTipoAdjunto(codigo: $codigo, descripcion: $descripcion, tipo_operacion: $tipo_operacion) {
                    ${recursos}
                }
            }`,
            variables: {codigo, descripcion, tipo_operacion}
        })
    },
    updateTipoAdjunto: (client, {id, update}, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateTipoAdjunto($id: String!, $update: JSON!){
                updateTipoAdjunto(id: $id, update: $update) {
                    ${recursos}
                }
            }`,
            variables: {id, update}
        })
    },
    deleteTipoAdjunto: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteTipoAdjunto($id: String!){
                deleteTipoAdjunto(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    restoreTipoAdjunto: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation restoreTipoAdjunto($id: String!){
                restoreTipoAdjunto(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    }
}

export default TipoAdjunto