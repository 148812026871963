import React, {Fragment, useState} from 'react'
import {Redirect} from 'react-router-dom'
import Slider from 'react-slick'
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import gql from "graphql-tag"

// import bg1 from '../../assets/utils/images/originals/bg1.jpg'
// import bg2 from '../../assets/utils/images/originals/bg2.jpg'
// import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from '../../Context'
import Auth from '../../Components/Models/Auth'
import {decodeToken, isToken, verifyJwt} from "../../utils/scripts"

let host = window.location.hostname
// let host = "actributario"
let bg1, bg2, bg3

if (host.includes('actributario')) {
    bg1 = require('../../assets/utils/images/originals/actributario/bg1.jpg')
    bg3 = require('../../assets/utils/images/originals/actributario/bg2.jpg')
    bg2 = require('../../assets/utils/images/originals/actributario/bg3.jpg')
} else {
    bg1 = require('../../assets/utils/images/originals/bg1.jpg')
    bg2 = require('../../assets/utils/images/originals/bg2.jpg')
    bg3 = require('../../assets/utils/images/originals/bg3.jpg')
}

const Reset = ({match}) => {
    let {token, user} = match.params,
        settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true
        }

    user = token ? decodeToken(token).email : ''

    const {auth, client, setClient, removeAllToasts, toast, appapi} = useAuth()

    const [email, setEmail] = useState(user)
    const [password, setPassword] = useState('')
    const [password_confirm, setPasswordConfirm] = useState('')

    const reset = user ? 'initial' : 'none'

    const postReset = () => {
        if (user) {
            if (email === '') {
                toast.error('Ingrese un Email!', {autoClose: 2000})
                return
            }
            if (password.length < 6 || password.length > 15) {
                toast.error('La contraseña debe ser mayor de 5 y menor de 16 caracteres', {autoClose: 2000})
                return
            }

            if (password !== password_confirm) {
                toast.error('La Contraseña no coincide!', {autoClose: 2000})
                setPassword('')
                setPasswordConfirm('')
                return
            }
            toast.success('Procesando...', {autoClose: 2000})
            Auth
                .passwordReset(client, {
                    email, password, token
                }, 'authentication, authorization, refresh, modulo_id')
                .then(response => {
                    removeAllToasts()
                    const {passwordReset} = response.data
                    // if (verifyJwt(passwordReset.authentication)) {
                    toast.warning('¡Seleccione un Empresa para Trabajar!', {autoClose: 5000})
                    // } else {
                    setClient(passwordReset)
                    appapi
                        .mutate({
                            mutation: gql`mutation resetEco(
                                    $email: String!,
                                    $password: String!,
                                ) {
                                    resetEco(
                                        email: $email, 
                                        password: $password
                                    ){
                                        email
                                    }
                                }`,
                            context: {headers: {isAuth: isToken()}},
                            variables: {
                                email, password
                            }
                        })
                        .then(() => {
                            toast.error("Contraseña Actualizada", {autoClose: 2000})
                        })
                        .catch(({message}) => {
                            toast.error(message, {autoClose: 2000})
                        })
                    // }
                })
                .catch(({message}) => {
                    removeAllToasts()
                    toast.error(message, {autoClose: 2000})
                })
        } else {
            toast.success('Procesando...', {autoClose: 2000})
            Auth
                .reset(client, {email}, 'authentication')
                .then(response => {
                    const {reset} = response.data
                    removeAllToasts()

                    if (reset === null) {
                        toast.error('¡Ingrese un email Válido!', {autoClose: 2000})
                        return
                    }

                    if (reset.authentication === 'reset') {
                        toast.error('Aún no ha verificado su email!', {autoClose: 2000})
                        return
                    }

                    toast.success('¡Te hemos enviado por correo el enlace para restablecer tu contraseña!', {autoClose: 2000})
                })
                .catch(({message}) => {
                    removeAllToasts()
                    toast.error(message, {autoClose: 2000})
                })
        }
    }

    return auth.authentication ? (
        <Redirect to={'/home'}/>
    ) : (
        <Fragment>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="8" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider {...settings}>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }}
                                    />
                                    {host.includes('actributario') ? null : (
                                    <div className="slider-content">
                                        <h3>Sistema para la gestión de Cursos </h3>
                                        <p>Se administrará los cursos del Instituto Cersa.</p>
                                    </div>)}
                                </div>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }}
                                    />
                                    {host.includes('actributario') ? null : (
                                    <div className="slider-content">
                                        <h3>Automatizamos</h3>
                                        <p>procesos de matricula, cursos y alumnado</p>
                                    </div>)}
                                </div>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }}
                                    />
                                    {host.includes('actributario') ? null : (
                                    <div className="slider-content">
                                        <h3>Administramos</h3>
                                        <p>Matriculas, alumnos, cursos.</p>
                                    </div>)}
                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            {/*<img src="/logo.png" alt="Logo"/>*/}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={host.includes('actributario') ? "/actributario/logo.png" : "/logo.png"} alt="Logo" />
                            </div>
                            <Row className="divider"/>
                            <h4 className="mb-0" style={{textAlign: 'center'}}>
                                <span>Restablecer Contraseña</span>
                            </h4>
                            <Row className="divider"/>
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Email</Label>
                                            <Input
                                                type="email"
                                                value={email}
                                                onChange={({target}) => setEmail(target.value)}
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} style={{display: `${reset}`}}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Nueva Contraseña</Label>
                                            <Input type="password" value={password}
                                                   onChange={({target}) => setPassword(target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} style={{display: `${reset}`}}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Confirmar Nueva Contraseña</Label>
                                            <Input
                                                type="password"
                                                value={password_confirm}
                                                onChange={({target}) => setPasswordConfirm(target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <Row className="divider"/>
                            <div className="d-flex align-items-center">
                                <div className="ml-auto">
                                    <Button color="primary" size="lg" onClick={postReset}>
                                        <FontAwesomeIcon icon={faUser}/> Resetear Contraseña
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default Reset
