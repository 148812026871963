import gql from "graphql-tag"

const Diario = {
    updateOrCreate: (client, {
        id, fecha, medio_pago, constancia, provisiones, destinos, glosa_provision, save_preferido, preferido,
        amarre_cc_id, tipo_cambio, periodo
    }, recursos) => client.mutate({
        mutation: gql`mutation createAsientoContable($id: String, $fecha: String!, $medio_pago: String, 
            $constancia: String, $provisiones: JSON!, $destinos: JSON!, $glosa_provision: String!, 
            $save_preferido: Boolean, $preferido: JSON, $amarre_cc_id: String, $tipo_cambio: Float, $periodo: String) {
                createAsientoContable(id: $id, fecha: $fecha, medio_pago: $medio_pago, constancia: $constancia, 
                    provisiones: $provisiones, destinos: $destinos, glosa_provision: $glosa_provision, 
                    save_preferido: $save_preferido, preferido: $preferido, amarre_cc_id: $amarre_cc_id, 
                    tipo_cambio: $tipo_cambio, periodo: $periodo) {
                    ${recursos}
                  }
                }`,
        variables: {
            id, fecha, medio_pago, constancia, provisiones, destinos, glosa_provision, save_preferido, preferido,
            amarre_cc_id, tipo_cambio: Number(tipo_cambio), periodo
        }
    }),
    updateOrCreateInvInicial: (client, {id, fecha, provisiones, glosa_provision}, recursos) => client.mutate({
        mutation: gql`mutation createAsientoInvInicial($id: String, $fecha: String!, $provisiones: JSON!, $glosa_provision: String!) {
                createAsientoInvInicial(id: $id, fecha: $fecha, provisiones: $provisiones, glosa_provision: $glosa_provision) {
                    ${recursos}
                  }
                }`,
        variables: {id, fecha, provisiones, glosa_provision}
    }),
    updateAsientoContable: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoContable($id: String!, $update: JSON!){
                  updateAsientoContable(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {
                ...asiento,
                tipo_cambio: Number(asiento.tipo_cambio),
                base_imponible: Number(asiento.base_imponible)
            }
        }
    }),
    updateAsientoInvInicial: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoInvInicial($id: String!, $update: JSON!){
                  updateAsientoInvInicial(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    createAsientoPlanilla: (client, {
        id, fecha, provisiones, destinos, glosa_provision, amarre_cc_id, save_preferido, preferido
    }, recursos) => client.mutate({
        mutation: gql`mutation createAsientoPlanilla($id: String, $fecha: String!, $provisiones: JSON!, 
            $destinos: JSON!, $glosa_provision: String!, $amarre_cc_id: String, $save_preferido: Boolean, 
            $preferido: JSON) {
            createAsientoPlanilla(id: $id, fecha: $fecha, provisiones: $provisiones, destinos: $destinos, 
                glosa_provision: $glosa_provision, amarre_cc_id: $amarre_cc_id, save_preferido: $save_preferido, preferido: $preferido) {
                ${recursos}
            }
        }`,
        variables: {id, fecha, provisiones, destinos, glosa_provision, amarre_cc_id, save_preferido, preferido}
    }),
    updateAsientoPlanilla: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoPlanilla($id: String!, $update: JSON!){
                  updateAsientoPlanilla(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    updateAsientoCierre: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoCierre($id: String!, $update: JSON!){
                  updateAsientoCierre(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    deleteAsiento: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteAsiento($id: String!){
                  deleteAsiento(id: $id) {
                      ${recursos}
                    }
                  }`,
        variables: {id}
    }),
    updateAsientoDestinoContable: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoDestinoContable($id: String!, $update: JSON!){
                  updateAsientoDestinoContable(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {id: asiento.id, update: {...asiento}}
    })
}

export default Diario
