import gql from "graphql-tag"

const Tributo = {
    // Queries
    allTributos: (client, recursos) => {
        return client.query({
            query: gql`query allTributos {
                allTributos {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    tributoById: (client, id, recursos) => {
        return client.query({
            query: gql`query tributoById($id: String!){
                tributoById(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: "no-cache"
        })
    },
    tributosByEmpresa: (client, recursos) => {
        return client.query({
            query: gql`query tributosByEmpresa {
                tributosByEmpresa {
                    ${recursos}
                }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    duplicatedTributo: (client, {t_tributos_id}, recursos) => {
        return client.query({
            query: gql`query duplicatedTributo($t_tributos_id: String!){
                duplicatedTributo(t_tributos_id: $t_tributos_id) {
                    ${recursos}
                }
            }`,
            variables: {t_tributos_id},
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    addTributo: (client, {t_tributos_id, cuenta_id}, recursos) => {
        return client.mutate({
            mutation: gql`mutation addTributo($t_tributos_id: String!, $cuenta_id: String!){
                addTributo(t_tributos_id: $t_tributos_id, cuenta_id: $cuenta_id) {
                    ${recursos}
                }
            }`,
            variables: {t_tributos_id, cuenta_id}
        })
    },
    updateTributo: (client, {id, update}, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateTributo($id: String!, $update: JSON!){
                updateTributo(id: $id, update: $update) {
                    ${recursos}
                }
            }`,
            variables: {id, update}
        })
    },
    deleteTributo: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteTributo($id: String!){
                deleteTributo(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    restoreTributo: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation restoreTributo($id: String!){
                restoreTributo(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id}
        })
    },
    // Custom Queries
    getByParam: (client, param, recursos) => client.query({
        query: gql`query codigosByParam($param: String!){
                codigosByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    getTributosByCodigos: (client, codigos, recursos) => {
        return client.query({
            query: gql`query getTributosByCodigos($codigos: [String]!){
                getTributosByCodigos(codigos: $codigos) {
                    ${recursos}
                }
            }`,
            variables: {codigos},
            fetchPolicy: "no-cache"
        })
    },
    existAsientoTributo: (client, numero_transaccion, recursos) => {
        return client.query({
            query: gql`query existAsientoTributo($numero_transaccion: String!){
                existAsientoTributo(numero_transaccion: $numero_transaccion) {
                    ${recursos}
                }
            }`,
            variables: {numero_transaccion},
            fetchPolicy: "no-cache"
        })
    }
}

export default Tributo