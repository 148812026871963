import React, {Fragment, useState, useEffect} from 'react'
import Ionicon from 'react-ionicons'
import {UncontrolledDropdown, DropdownToggle, DropdownMenu} from 'reactstrap'
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component"
import PerfectScrollbar from "react-perfect-scrollbar"
import moment from "moment"
import {Link, useHistory} from "react-router-dom"

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'

import {useAuth} from '../../../Context'
import {decodeToken} from "../../../utils/scripts"
import {Notificacion, Operacion} from "../../../Components/Models"

Array.prototype.uniqueObject = function (element) {
    return [...new Set(this.map(item => item[element]))]
}

const Notificaciones = () => {
    const {client, auth, toast, setAuth} = useAuth()
    const history = useHistory()
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        if (!auth.modulo_id) return
        if (auth.modulo_id === '') return
        const {pathname} = window.location
        let update_pendientes = false
        switch (pathname) {
            case '/app/empresas':
                update_pendientes = true
                break
            case '/fac/factura':
                update_pendientes = true
                break
        }
        Notificacion
            .getNotificaciones(client, 'id, mensaje, created_at, titulo, link')
            .then(response => {
                let {ListNotificaciones} = response.data
                ListNotificaciones = ListNotificaciones.map(element => {
                    return ({
                        id: element.id,
                        message: element.mensaje,
                        subject: element.titulo,
                        created_at: `Creado: ${moment(element.created_at).format('YYYY-MM-DD HH:mm:ss')}`,
                        to: element.link
                    })
                })

                setNotifications(prevState => {
                    return ListNotificaciones.concat(prevState)
                })
            })
        if (!update_pendientes) return
        Operacion
            .getCpePendientes(client, 'fecha_emision, local_id, local {direccion, codigo_serie, empresa {ruc, razon_social, user_id}}')
            .then(response => {
                let {cpePendientes} = response.data
                let user_id = ''
                cpePendientes = cpePendientes.map(element => {
                    user_id = element.local.empresa.user_id
                    return {
                        ...element,
                        ruc: element.local.empresa.ruc,
                        codigo_serie: element.local.codigo_serie
                    }
                })
                const newPendientes = []

                const {id, role_id} = decodeToken(auth.authentication)
                const empresas = cpePendientes.uniqueObject('ruc')
                if ((empresas.length === 1 && role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041' && id !== user_id) || role_id !== 'ea7e35be-220b-11ec-bdf8-13a4a75f3041') {
                    for (const l of cpePendientes.uniqueObject('local_id')) {
                        const pendientes = cpePendientes.filter(({local_id}) => local_id === l)
                        const {local} = cpePendientes.find(({local_id}) => local_id === l)
                        newPendientes.push({
                            message: `${local.empresa.ruc} - ${local.direccion}`,
                            subject: `Serie del Local: ${local.codigo_serie}`,
                            to: '',
                            created_at: `Fecha de Emisión: ${pendientes.uniqueObject('fecha_emision').join(', ')}`
                        })
                    }
                    setNotifications(prevState => {
                        return prevState.concat(newPendientes)
                    })
                    // setNotifications(newPendientes)
                    return
                }

                if (role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041')
                    for (const e of cpePendientes.uniqueObject('ruc')) {
                        const pendientes = cpePendientes.filter(({ruc}) => ruc === e)
                        const {local} = cpePendientes.find(({ruc}) => ruc === e)
                        newPendientes.push({
                            message: `${local.empresa.ruc} - ${local.empresa.razon_social}`,
                            subject: `Serie del Local: ${pendientes.uniqueObject('codigo_serie').join(', ')}`,
                            to: '',
                            created_at: `Fecha de Emisión: ${pendientes.uniqueObject('fecha_emision').join(', ')}`
                        })
                    }

                setNotifications(prevState => {
                    return prevState.concat(newPendientes)
                })
                Operacion
                    .getGuiasPendientes(client, 'fecha_emision, local_id, local {direccion, codigo_serie, empresa {ruc, razon_social, user_id}}')
                    .then(response => {
                        const {guiasPendientes} = response.data
                        let newGuias = []
                        const enterprises = guiasPendientes.uniqueObject('ruc')
                        if ((enterprises.length === 1 && role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041' && id !== user_id) || role_id !== 'ea7e35be-220b-11ec-bdf8-13a4a75f3041') {
                            for (const l of guiasPendientes.uniqueObject('local_id')) {
                                const pendientes = guiasPendientes.filter(({local_id}) => local_id === l)
                                const {local} = guiasPendientes.find(({local_id}) => local_id === l)
                                newGuias.push({
                                    message: `${local.empresa.ruc} - ${local.direccion}`,
                                    subject: `Guías pendientes, serie: ${local.codigo_serie}`,
                                    to: '',
                                    created_at: `Fecha de Emisión: ${pendientes.uniqueObject('fecha_emision').join(', ')}`
                                })
                            }
                            setNotifications(prevState => {
                                return prevState.concat(newGuias)
                            })
                            return
                        }
                        if (role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041') {
                            for (const e of guiasPendientes.uniqueObject('ruc')) {
                                const pendientes = guiasPendientes.filter(({ruc}) => ruc === e)
                                const {local} = guiasPendientes.find(({ruc}) => ruc === e)
                                newGuias.push({
                                    message: `${local.empresa.ruc} - ${local.empresa.razon_social}`,
                                    subject: `Guías pendientes, serie: ${pendientes.uniqueObject('codigo_serie').join(', ')}`,
                                    to: '',
                                    created_at: `Fecha de Emisión: ${pendientes.uniqueObject('fecha_emision').join(', ')}`
                                })
                            }
                            setNotifications(prevState => {
                                return prevState.concat(newGuias)
                            })
                        }
                    })
            })
            .catch(({message}) => toast.error(message, {autoClose: 2000}))
    }, [auth.modulo_id])

    const contenido = (message, id, to) => {
        let pdf = message.includes('PDFs')
        let comentarios = message.includes('COMENTARIO')
        if (pdf) {
            const array = to.split('/')
            fetch(`${process.env.REACT_APP_API_ECOCONT}/storage/masivo/${array[Number(array.length) - 1]}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const element = document.createElement('a')
                    const file = new Blob([blob], {type: 'text/plain;charset=utf-8'})
                    element.href = URL.createObjectURL(file)
                    element.download = `Comprobantes masivos.pdf`
                    document.body.appendChild(element)
                    element.click()
                })

            Notificacion
                .update(client, {id, update: {readed_at: true}}, 'id')
                .then(response => response)
        }
        if (comentarios) {
            setAuth(prevState => ({
                ...prevState,
                modulo_id: "1fd2848e-b6b5-11ec-8c3d-18c04dc1e3d5"
            }))
            history.push(to)
            Notificacion
                .update(client, {id, update: {readed_at: true}}, 'id')
                .then(response => response)
        }
    }

    return (
        <div className='header-dots'>
            <UncontrolledDropdown>
                <DropdownToggle className='p-0 mr-2' color='link'>
                    <div className='icon-wrapper icon-wrapper-alt rounded-circle'>
                        <div
                            className={`icon-wrapper-bg bg-${notifications.length === 0 ? 'success ' : 'danger'}`}/>
                        <Ionicon beat={notifications.length > 0}
                                 color={notifications.length === 0 ? '#3ac47d ' : '#d92550'} fontSize='23px'
                                 icon='md-notifications-outline'/>
                        {notifications.length > 0 ? (
                            <div className='badge badge-dot badge-dot-sm badge-danger'>Notificaciones</div>
                        ) : (
                            ''
                        )}
                    </div>
                </DropdownToggle>

                <DropdownMenu right className='dropdown-menu-xl rm-pointers'>
                    <div className='dropdown-menu-header mb-0'>
                        <div className='dropdown-menu-header-inner bg-deep-blue'>
                            <div className='menu-header-image opacity-1'
                                 style={{backgroundImage: 'url(' + city3 + ')'}}/>
                            <div className='menu-header-content text-dark'>
                                <h5 className='menu-header-title'>Notificaciones Comprobantes</h5>
                                <h6 className="menu-header-subtitle">Tú
                                    tienes <b>{notifications.length} notificación(es)</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>
                            <div className="p-3">
                                <VerticalTimeline className="vertical-time-simple vertical-without-time"
                                                  layout="1-column">
                                    {notifications.map(({
                                                            created_at, message, to, subject, id = null
                                                        }, index) => (
                                        <VerticalTimelineElement key={index} className="vertical-timeline-item">
                                            <h4 className="timeline-title">
                                                {message}
                                                <br/>
                                                <small className="text-info">{subject}</small>
                                                <br/>
                                                <small
                                                    className="text-success">{created_at}</small><br/>
                                                {
                                                    !!to && to !== '' ?
                                                        <Link onClick={() => contenido(subject, id, to)}>
                                                            Ver detalle
                                                        </Link> : <></>
                                                }
                                            </h4>
                                        </VerticalTimelineElement>
                                    ))}
                                </VerticalTimeline>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default Notificaciones
