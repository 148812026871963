import gql from 'graphql-tag'

const CompraModel = {
    create: (client, compra, recursos) => client.mutate({
        mutation: gql`mutation createCompra(
                        $fecha_emision: String,
                        $fecha_vencimiento: String,
                        $serie: String,
                        $correlativo: String,
                        $fecha: String,
                        $serie_mod: String,
                        $correlativo_mod: String,
                        $fecha_const: String,
                        $numero_const: String,
                        $importe: Float,
                        $importe_afectacion: Float,
                        $icbper: Float,
                        $otros: Float,
                        $isc: Float,
                        $tc: Float,
                        $total: Float,
                        $comprobante: String,
                        $persona: String!,
                        $comprobante_mod: String,
                        $tipo_operacion: String,
                        $afecto: String,
                        $obligacion: String,
                        $asientos: JSON,
                        $gastos: JSON,
                        $glosa: String,
                        $movimiento: JSON,
                        $transferencia: JSON,
                        $dua: String,
                        $pais: String,
                        $tipo_renta: String,
                        $comprobante_dua: String,
                        $serie_dua: String,
                        $correlativo_dua: String,
                        $doble_imposicion: String,
                        $save_preferido: Boolean,
                        $preferido: JSON,
                        $porcentaje_igv: Float,
                        $amarre_cc_id: String,
                        $isOnlyRecibo: Boolean,
                        $cps: JSON,
                        $cuotas: JSON
                     ) {
                        createCompra(
                        fecha_emision: $fecha_emision,
                        fecha_vencimiento: $fecha_vencimiento,
                        serie: $serie,
                        correlativo: $correlativo,
                        fecha: $fecha,
                        serie_mod: $serie_mod,
                        correlativo_mod: $correlativo_mod,
                        fecha_const: $fecha_const,
                        numero_const: $numero_const,
                        importe: $importe,
                        importe_afectacion: $importe_afectacion,
                        icbper: $icbper,
                        otros: $otros,
                        isc: $isc,
                        tc: $tc,
                        total: $total,
                        comprobante: $comprobante,
                        persona: $persona,
                        comprobante_mod: $comprobante_mod,
                        tipo_operacion: $tipo_operacion,
                        afecto: $afecto,
                        obligacion: $obligacion,
                        asientos: $asientos,
                        gastos: $gastos,
                        glosa: $glosa,
                        movimiento: $movimiento,
                        transferencia: $transferencia,
                        dua: $dua,
                        pais: $pais,
                        tipo_renta: $tipo_renta,
                        comprobante_dua: $comprobante_dua,
                        serie_dua: $serie_dua,
                        correlativo_dua: $correlativo_dua,
                        doble_imposicion: $doble_imposicion,
                        save_preferido: $save_preferido,
                        preferido: $preferido,
                        porcentaje_igv: $porcentaje_igv,
                        amarre_cc_id: $amarre_cc_id,
                        isOnlyRecibo: $isOnlyRecibo,
                        cps: $cps,
                        cuotas: $cuotas
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            ...compra,
            fecha_vencimiento: compra.fecha_vencimiento !== '' ? compra.fecha_vencimiento : null,
            fecha_const: compra.fecha_const !== '' ? compra.fecha_const : null,
            fecha: compra.fecha !== '' ? compra.fecha : null,
            comprobante_mod: compra.comprobante_mod !== '' ? compra.comprobante_mod : null,
            icbper: Number(compra.icbper),
            otros: Number(compra.otros),
            isc: Number(compra.isc),
            tc: Number(compra.tc),
            importe: Number(compra.importe),
            importe_afectacion: Number(compra.importe_afectacion)
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`query {
            compras {
                ${recursos}
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query compra($id: String!){
            compra(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    update: (client, compra, recursos) => client.mutate({
        mutation: gql`mutation updateCompra($id: String!, $update: JSON!){
            updateCompra(id: $id, update: $update) {
                ${recursos}
            }
        }`,
        variables: {
            id: compra.id,
            update: {
                ...compra
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedCompra($id: String!){
                  deletedCompra(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarCompra($id: String!){
            activarCompra(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id}
    }),
    validarCompra: (client, {type, data}, recursos) => client.query({
        query: gql`query validarCompra($type: String!, $data: JSON!) {
            validarCompra(type: $type, data: $data) {
                ${recursos}
            }
        }`,
        variables: {type, data},
        fetchPolicy: 'no-cache'
    }),
}

export default CompraModel
