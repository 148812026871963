import gql from 'graphql-tag'

const TipoOperacionModel = {
    getById: (client, id, recursos) => client.query({
        query: gql`query tipoOperacionbyId ($id: String!){
            tipoOperacionbyId(id: $id){
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
       tipoOperaciones{
       ${recursos}
       }
       }`,
        fetchPolicy: 'no-cache'

    }),
    getAllCompraVenta: (client, recursos) => client.query({
        query: gql`{
       tipoOperacionesCV{
       ${recursos}
       }
       }`,
        fetchPolicy: 'no-cache'

    })
}
export default TipoOperacionModel
