import gql from 'graphql-tag'

const ProveedorModel = {
    proveedorById: (client, id, recursos) => client.query({
        query: gql`query persona($id: String!){
            persona(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query proveedoresByParam($param: String!) {
            proveedoresByParam(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    getByParam2: (client, param, recursos) => client.query({
        query: gql`query proveedoresByParamMovimientos($param: String!) {
            proveedoresByParamMovimientos(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
}

export default ProveedorModel