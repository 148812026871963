import gql from 'graphql-tag'

const ClienteModel = {
    getByDefault: (client, recursos) => client.query({
        query: gql`{
            clienteByDefault {
                  ${recursos}
              }
          }`,
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query clientesByParam($param: String!) {
            clientesByParam(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    getByParam2: (client, param, recursos) => client.query({
        query: gql`query clientesByParamMovimientos($param: String!) {
            clientesByParamMovimientos(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
}

export default ClienteModel