import gpl from 'graphql-tag';

const ConciliacionModel = {
    createConciliacion: (client, periodo, conciliaciones, bancos, fechaInicial, saldoInicial) => client.mutate({
        mutation: gpl`mutation createConciliacion(
            $periodo: String!
            $conciliaciones: [ConciliacionInputType!]!
            $bancos: [BancoInputType!]!
            $fechaInicial: String!
            $saldoInicial: Float!
        ){
            createConciliacion(
                periodo: $periodo
                conciliaciones: $conciliaciones
                bancos: $bancos
                fechaInicial: $fechaInicial
                saldoInicial: $saldoInicial
            ){
                iguales {
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enConciliacionesNoEnBancos{
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enBancosNoEnConciliaciones{
                    fecha
                    descripcion
                    numeroOp
                    tipoOp
                    debe
                    haber
                }
            }
        }`,
        variables: {
            periodo, conciliaciones, bancos, fechaInicial, saldoInicial
        }
    }),
    getConciliaciones: (client) => client.query({
        query: gpl`query getConciliacionesApiDrive{
            getConciliacionesApiDrive{
                fechaInicial
                saldoInicial
                iguales{
                    id
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
            }
        }`
    }),
    actualizarConciliacion: (client, conciliacion) => client.mutate({
        mutation: gpl`mutation actualizarConciliacion(
            $conciliacion: ConciliacionInputType!
        ){
            actualizarConciliacion(
                conciliacion: $conciliacion
            )
        }`,
        variables: {
            conciliacion
        }
    }),
    actualizarSaldoFechaInicial: (client, saldoInicial, fechaInicial) => client.mutate({
        mutation: gpl`mutation actualizarSaldoFechaInicial(
            $saldoInicial: Float!
            $fechaInicial: String!
        ){
            actualizarSaldoFechaInicial(
                saldoInicial: $saldoInicial
                fechaInicial: $fechaInicial
            )
        }`,
        variables: {
            saldoInicial, fechaInicial
        }
    }),
    getResumen: (client, bancos) => client.query({
        query: gpl`query getResumenApiDrive($bancos: [BancoType!]!){
            getResumenApiDrive(bancos: $bancos){
                fechaInicial
                saldoInicial
                iguales{
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enConciliacionesNoEnBancos{
                    abono
                    cargo
                    descripcion
                    fecha
                    numeroOp
                    tipoOp
                    validada
                }
                enBancosNoEnConciliaciones{
                    fecha
                    descripcion
                    numeroOp
                    tipoOp
                    debe
                    haber
                }
            }
        }`,
        variables: {
            bancos
        }
    }),

    eliminarItemConciliacion: (client, conciliacion) => client.mutate({
        mutation: gpl`mutation eliminarItemConciliacion($conciliacion: ConciliacionInputType!){
            eliminarItemConciliacion(conciliacion: $conciliacion)
        }`,
        variables: {
            conciliacion
        }
    }),

    delete: (client) => client.mutate({
        mutation: gpl`mutation eliminarConciliacion{
            eliminarConciliacion
        }`
    })
}

export default ConciliacionModel;