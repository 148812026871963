import gql from "graphql-tag"

const Payroll = {
    createFeriade: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation createFeriade(
            $id:          String,
            $fecha:       String,
            $description: String
        ){
            createFeriade(
                id:          $id,
                fecha:       $fecha,
                description: $description
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id: send.id,
            fecha: send.feriade,
            description: send.description
        }
    }),

    updateFeriade: (client, id, recursos) => client.mutate({
        mutation: gql`mutation updateFeriade(
            $id:          String
        ){
            updateFeriade(
                id:          $id
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id: id
        }
    }),
    filterPlanillaTrabajadorez: (client, empresa_id, recursos) => client.query({
        query: gql`query filterPlanillaTrabajadores(
            $empresa_id: String
            $contract: Boolean
        ){
            filterPlanillaTrabajadores(
                empresa_id: $empresa_id,
                contract: $contract
            ) {
                ${recursos}
            } 
        }`,
        variables: {
            empresa_id: empresa_id,
            contract: true
        },
        fetchPolicy: "no-cache"
    }),

    filterPlanillaTrabajadores: (client, empresa_id, recursos) => client.query({
        query: gql`query filterPlanillaTrabajadores($empresa_id: String){
            filterPlanillaTrabajadores(empresa_id: $empresa_id) {
                ${recursos}
            } 
        }`,
        variables: {
            empresa_id: empresa_id
        },
        fetchPolicy: "no-cache"
    }),

    filterPlanillaTrabajadore: (client, planilla_id, recursos) => client.query({
        query: gql`query filterPlanillaTrabajadores($planilla_id: String){
            filterPlanillaTrabajadores(planilla_id: $planilla_id) {
                ${recursos}
            } 
        }`,
        variables: {
            planilla_id: planilla_id
        },
        fetchPolicy: "no-cache"
    }),

    updatesFeriade: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation updatesFeriade(
            $id:          String,
            $date:        String,
            $description: String,
        ){
            updatesFeriade(
                id:          $id,
                date:        $date,
                description: $description,
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id: send.id,
            date: send.feriade,
            description: send.description
        }
    }),

    selectFeriade: (client, {id}, recursos) => client.query({
        query: gql`query selectFeriade($id: String){
            selectFeriade(id: $id) {
                ${recursos}
            } 
        }`,
        variables: {
            id: id
        },
        fetchPolicy: "no-cache"
    }),

    selectFeriades: (client, employee_id, recursos) => client.query({
        query: gql`query selectFeriade($employee_id: String){
            selectFeriade(employee_id: $employee_id) {
                ${recursos}
            }
        }`,
        variables: {
            employee_id: employee_id
        },
        fetchPolicy: "no-cache"
    }),

    tableJson: (client) => client.query({
        query: gql`query{
          tableJson{
            description
            code
          }
        }`,
        variables: {},
        fetchPolicy: "no-cache"
    }),

    planillalist: (client, empresa_id, recursos) => client.query({
        query: gql`query planillalist($empresa_id: String){
            planillalist(empresa_id: $empresa_id) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: String(empresa_id)
        },
        fetchPolicy: "no-cache"
    }),

    createPlanilla: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation createPlanilla(
                $empresa_id:    String,
                $nombre:        String,
                $description:   String,
                $regimen:       String,
                $coin:          String,
                $periode:       String,
                $horas1:        Int,
                $horas2:        Int
            ){
            createPlanilla(
                empresa_id:    $empresa_id,
                nombre:        $nombre,
                description:   $description,
                regimen:       $regimen,
                coin:          $coin,
                periode:       $periode,
                horas1:        $horas1,
                horas2:        $horas2
            ){
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: send.id,
            nombre: send.nombre,
            description: send.descripcion,
            regimen: send.regimen,
            coin: send.moneda,
            periode: send.periodicidad,
            horas1: Number(send.porcentaje0),
            horas2: Number(send.porcentaje1)
        }
    }),

    editPlanilla: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation editPlanilla(
                $id:            String,
                $nombre:        String,
                $description:   String,
                $regimen:       String,
                $coin:          String,
                $periode:       String,
                $horas1:        Int,
                $horas2:        Int
            ){
            editPlanilla(
                id:            $id,
                nombre:        $nombre,
                description:   $description,
                regimen:       $regimen,
                coin:          $coin,
                periode:       $periode,
                horas1:        $horas1,
                horas2:        $horas2
        ){
            ${recursos}
        }
    }`,
        variables: {
            id: send.id,
            nombre: send.nombre,
            description: send.descripcion,
            regimen: send.regimen,
            coin: send.moneda,
            periode: send.periodicidad,
            horas1: Number(send.porcentaje0),
            horas2: Number(send.porcentaje1)
        }
    }),

    deletePlanilla: (client, {id}, recursos) => client.mutate({
        mutation: gql`mutation deletePlanilla(
                $id: String
            ){
            deletePlanilla(
                id: $id
            ){
                ${recursos}
            }
    }`,
        variables: {
            id: id
        }
    }),


    rollbackPlanilla: (client, {id}, recursos) => client.mutate({
        mutation: gql`mutation rollbackPlanilla(
                $id: String
            ){
            rollbackPlanilla(
                id: $id
            ){
                ${recursos}
            }
    }`,
        variables: {
            id: id
        }
    }),
    filterPlanillas: (client, {send}, recursos) => client.query({
        query: gql`query filterPlanillas(
                $id:         String,
                $empresa_id: String
            ){
            filterPlanillas(
                id:         $id,
                empresa_id: $empresa_id
            ){
                ${recursos}
            }
        }`,
        variables: {
            id: send.id,
            empresa_id: send.empresa_id
        },
        fetchPolicy: "no-cache"
    }),

    filterPlanilla: (client, {send}, recursos) => client.query({
        query: gql`query filterPlanilla($id: String, $empresa_id: String) {
            filterPlanilla(id: $id, empresa_id: $empresa_id) {
                ${recursos}
            }
        }`,
        variables: {
            id: send.id, empresa_id: send.empresa_id
        },
        fetchPolicy: "no-cache"
    }),

    reportesPayroll: (client, {send}) => client.query({
        query: gql` query reportesPayroll(
            $planilla: String,
            $employee: String,
            $banco: String,
            $periodo1: String,
            $periodo2: String
        ){
            reportesPayroll(
                planilla: $planilla,
                employee: $employee,
                banco: $banco,
                periodo1: $periodo1,
                periodo2: $periodo2,
            ){
                data
            }
        }
        `,
        variables: {
            planilla: send.planilla,
            employee: send.employee,
            banco: send.banco,
            periodo1: send.periodo1,
            periodo2: send.periodo2,
        },
        fetchPolicy: "no-cache"
    }),
    reporteGrati: (client, {send}) => client.query({
        query: gql` query reporteGrati(
            $planilla: String,
            $employee: String,
            $banco: String,
            $periodo1: String,
            $periodo2: String
        ){
            reporteGrati(
                planilla: $planilla,
                employee: $employee,
                banco: $banco,
                periodo1: $periodo1,
                periodo2: $periodo2,
            ){
                data
            }
        }
        `,
        variables: {
            planilla: send.planilla,
            employee: send.employee,
            banco: send.banco,
            periodo1: send.periodo1,
            periodo2: send.periodo2,
        },
        fetchPolicy: "no-cache"
    }),
    reportesCts: (client, {send}) => client.query({
        query: gql` query reportesCts(
            $planilla: String,
            $employee: String,
            $banco: String,
            $periodo1: String,
            $periodo2: String
        ){
            reportesCts(
                planilla: $planilla,
                employee: $employee,
                banco: $banco,
                periodo1: $periodo1,
                periodo2: $periodo2,
            ){
                data
            }
        }
        `,
        variables: {
            planilla: send.planilla,
            employee: send.employee,
            banco: send.banco,
            periodo1: send.periodo1,
            periodo2: send.periodo2,
        },
        fetchPolicy: "no-cache"
    }),

    selectAllDefault: (client, id, recursos) => client.query({
        query: gql`query selectAllDefault($id: String){
            selectAllDefault(id: $id){
                ${recursos}
            }
        }`,
        variables: {
            id: id
        },
        fetchPolicy: "no-cache"
    }),

    getJson: (client, empresa_id, recursos) => client.query({
        query: gql` query getJson($empresa_id: String){
            getJson(empresa_id: $empresa_id){
                ${recursos}
            }
        }
        `,
        variables: {
            empresa_id: empresa_id
        },
        fetchPolicy: "no-cache"
    }),

    getIngresos: (client, recursos) => client.query({
        query: gql` query getIngresos{
            getIngresos{
                ${recursos}
            }
        }
        `,
        variables: {},
        fetchPolicy: "no-cache"
    }),

    getDescuentos: (client, recursos) => client.query({
        query: gql` query getDescuentos{
            getDescuentos{
                ${recursos}
            }
        }
        `,
        variables: {},
        fetchPolicy: "no-cache"
    }),

    updateJson: (client, {send}, recursos) => client.query({
        query: gql` query updateJson(
            $id: String
            $asc_des: JSON
            $asc_des_debehaber: JSON
        ){
            updateJson(
                id: $id
                asc_des: $asc_des
                asc_des_debehaber: $asc_des_debehaber
            ){
                ${recursos}
            }
        }
        `,
        variables: {
            id: send.id,
            asc_des: send.asc_des,
            asc_des_debehaber: send.asc_des_debehaber
        },
        fetchPolicy: "no-cache"
    }),
    getPreViewBoleta: (client, {id_contract, periodo}, recursos) => client.query({
        query: gql`query previewBoleta($id_contract:String, $periodo: String) {
                previewBoleta(id_contract: $id_contract, periodo: $periodo) {
                   ${recursos}
                }
        }`,
        variables: {id_contract, periodo},
        fetchPolicy: 'no-cache'
    }),
    detalleTrabajador: (client, {id_contract, periodo, onDownload}, recursos) => client.query({
        query: gql`query detalleTrabajador($id_contract:String, $periodo: String, $onDownload: Boolean) {
                detalleTrabajador(id_contract: $id_contract, periodo: $periodo, onDownload: $onDownload) {
                   ${recursos}
                }
        }`,
        variables: {id_contract, periodo, onDownload},
        fetchPolicy: 'no-cache'
    }),
    renta5ta: (client, {id_empleado, periodo}, recursos) => client.query({
        query: gql`query rentaQuinta($id_empleado:String, $periodo: String) {
                rentaQuinta(id_empleado: $id_empleado, periodo: $periodo) {
                   ${recursos}
                }
        }`,
        variables: {id_empleado, periodo},
        fetchPolicy: 'no-cache'
    }),
    sendEmail: (client, {
        id_contract, email, type, periodo, fileName, xml, id_operacion, contenedor
    }, recursos) => client.query({
        query: gql`query sendEmail($id_contract:String, $email: String!, $type: String, $periodo: String,
         $fileName: String, $xml: JSON, $id_operacion: String, $contenedor: Boolean){
                sendEmail(id_contract: $id_contract, email: $email, type: $type, periodo: $periodo, 
                fileName: $fileName, xml: $xml, id_operacion: $id_operacion, contenedor: $contenedor){
                    ${recursos}
                }
        }`,
        variables: {id_contract, email, type, periodo, fileName, xml, id_operacion, contenedor},
        fetchPolicy: 'no-cache'
    }),
    sendBoletaWhatsapp: (client, {phone, id_contract, periodo}, recursos) => client.query({
        query: gql`query sendBoletaWhatsapp($phone: String!, $id_contract: String, $periodo: String){
              sendBoletaWhatsapp(phone: $phone, id_contract: $id_contract, periodo: $periodo){
              ${recursos}
              } 
        }`,
        variables: {phone, id_contract, periodo},
        fetchPolicy: 'no-cache'
    }),
    excelPlanilla: (client, {send}, recursos) => client.query({
        query: gql`query excelPlanilla($id: String, $empresa_id: String, $periodo: String, $type: String){
            excelPlanilla(id: $id, empresa_id: $empresa_id, periodo: $periodo, type: $type){
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {
            id: send.id,
            empresa_id: send.empresa_id,
            periodo: send.periodo,
            type: send.type
        },
        fetchPolicy: "no-cache"
    }),
    detalleLiquidacion: (client, {id_employee, periodo}, recursos) => client.query({
        query: gql`query detalleLiquidacion($id_employee:String, $periodo: String) {
                detalleLiquidacion(id_employee: $id_employee, periodo: $periodo) {
                   ${recursos}
                }
        }`,
        variables: {id_employee, periodo},
        fetchPolicy: 'no-cache'
    }),
    byID: (client, id, recursos) => client.query({
        query: gql`query payrrolID($id: String){
            payrrolID(id: $id){
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    permisoByPlanilla: (client, recursos) => client.query({
        query: gql`query permisoByPlanilla {
            permisoByPlanilla {
                ${recursos}        
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    trabajadorByDNI: (client, dni, fecha) => client.query({
        query: gql`query trabajadorByDNI($dni: String, $fecha: String){
                trabajadorByDNI(dni: $dni, fecha: $fecha){
                   result  
              }   
        } `,
        variables: {dni, fecha},
        fetchPolicy: 'no-cache'
    }),
    validezImportacionHoras: (client, datos) => client.query({
        query: gql`query validezImportacionHoras($datos: JSON){
                validezImportacionHoras(datos: $datos){
                result   
             }
        }`,
        variables: {datos},
        fetchPolicy: 'no-cache'
    }),
    saveRegistroHoras: (client, datos) => client.query({
        query: gql`query guardarRegistroHoras($datos: JSON){
                    guardarRegistroHoras(datos: $datos){
                    validate
              }
        }`,
        variables: {datos},
        fetchPolicy: 'no-cache'
    }),
    validarFaltasMasivas: (client, datos) => client.query({
        query: gql`query validarFechasFaltas($datos: JSON){
                validarFechasFaltas(datos: $datos){
                 result                   
             }
        }`,
        variables: {datos},
        fetchPolicy: 'no-cache'
    }),
    guardarImportacionFaltas: (client, datos) => client.query({
        query: gql`query guardarFaltasInjustificadas($datos: JSON){
            guardarFaltasInjustificadas(datos: $datos){
               validate  
           }
        }`,
        variables: {datos},
        fetchPolicy: 'no-cache'
    })
}

export default Payroll
