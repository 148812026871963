import gql from 'graphql-tag'

const ResponsableModel = {
    getByParam: (client, param, recursos) => client.query({
        query: gql`query responsablesByParam($param: String!) {
            responsablesByParam(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    })
}

export default ResponsableModel