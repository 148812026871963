import React, {Fragment, useEffect, useState} from "react"
import Chart from "react-apexcharts"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChartLine} from "@fortawesome/free-solid-svg-icons"
import {faSpinner} from "@fortawesome/fontawesome-free-solid"

import {useAuth} from "../../../Context"
import {Home} from "../../../Components/Models"
import {useSwitch} from "../../../hooks"

const ComprasVentas = () => {
    const {client, toast, removeAllToasts} = useAuth()

    const [mixedChart, setMixedChart] = useState({
        options: {
            chart: {height: 350, type: 'area', toolbar: {show: true}},
            stroke: {width: [4, 4]},
            labels: [],
            xaxis: {type: 'month'},
            yaxis: [{title: {text: 'Importes'}}]
        },
        series: []
    })

    const [include_notes, switchIncludeNotes] = useSwitch({
        yes: '', not: ''
    })

    useEffect(() => {
        toast.success(<span><FontAwesomeIcon icon={faSpinner} spin/> Procesando...</span>)
        Home
            .comprasVentasByMonths(client, {include_notes}, 'labels, series')
            .then(response => {
                const {labels, series} = response.data.comprasVentasByMonths
                setMixedChart(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        labels
                    },
                    series
                }))
                removeAllToasts()
                toast.success('Consulta exitosa', {autoClose: 2000})
            })
    }, [include_notes])

    useEffect(() => {
    }, [mixedChart])
    return (
        <Fragment>
            <span style={{fontWeight: 'bold'}}>
                <FontAwesomeIcon icon={faChartLine} className='text-warning mr-2'/>COMPRAS Y VENTAS:
            </span>
            <div className="text-center">
                Solo CPE's {switchIncludeNotes} Notas + CPE's
            </div>
            <div className="bar">
                <Chart options={mixedChart.options} series={mixedChart.series} type="line" width="100%" height="300px"/>
            </div>
        </Fragment>
    )
}

export default ComprasVentas
