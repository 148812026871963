import gql from "graphql-tag"

const ControlInterno = {
    // Queries
    getAllOperacionesByEmpresaId: (client, empresa_id) => {
        return client.query({
            query: gql`query getAllOperacionesByEmpresaId($empresa_id: String!){
                getAllOperacionesByEmpresaId(empresa_id: $empresa_id)
            }`,
            variables: {empresa_id},
            context: { timeout: 480000 },
            fetchPolicy: "no-cache"
        })
    },
    getAllPCGEByEmpresaId: (client, empresa_id) => {
        return client.query({
            query: gql`query getAllPCGEByEmpresaId($empresa_id: String!){
                getAllPCGEByEmpresaId(empresa_id: $empresa_id)
            }`,
            variables: {empresa_id},
            context: { timeout: 480000 },
            fetchPolicy: "no-cache"
        })
    },
    getAllDuplicatedPCGEByEmpresaId: (client, empresa_id, isReport) => {
        return client.query({
            query: gql`query getAllDuplicatedPCGEByEmpresaId($empresa_id: String!, $isReport: Boolean){
                getAllDuplicatedPCGEByEmpresaId(empresa_id: $empresa_id, isReport: $isReport)
            }`,
            variables: {empresa_id, isReport},
            context: { timeout: 480000 },
            fetchPolicy: "no-cache"
        })
    },
    // Mutations
    deleteAllOperacionesByEmpresaId: (client, empresa_id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAllOperacionesByEmpresaId($empresa_id: String!){
                deleteAllOperacionesByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            context: { timeout: 480000 },
            variables: {empresa_id}
        })
    },
    deleteAllPCGEByEmpresaId: (client, empresa_id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAllPCGEByEmpresaId($empresa_id: String!){
                deleteAllPCGEByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            context: { timeout: 480000 },
            variables: {empresa_id}
        })
    },
    deleteAllDuplicatedPCGEByEmpresaId: (client, empresa_id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteAllDuplicatedPCGEByEmpresaId($empresa_id: String!){
                deleteAllDuplicatedPCGEByEmpresaId(empresa_id: $empresa_id) {
                    ${recursos}
                }
            }`,
            context: { timeout: 480000 },
            variables: {empresa_id}
        })
    },
}

export default ControlInterno