import gql from 'graphql-tag'

const AsientoPredisenadoModel = {
    create: (client, {libro, codigo, nombre, provision}, recursos) => client.mutate({
        mutation: gql`mutation createAsientoPredisenado($libro: String!, $codigo: String!, $nombre: String!, $provision: JSON!){
            createAsientoPredisenado(libro: $libro, codigo: $codigo, nombre: $nombre, provision: $provision){
                ${recursos}
            }
        }`,
        variables: {libro, codigo, nombre, provision}
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
            asientosPredisenados {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query asientoPredisenado($id:String!){
            asientoPredisenado(id: $id){
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getByLibro: (client, libro, recursos) => client.query({
        query: gql`query asientoPredisenadoByLibro ($libro: String!) {
            asientoPredisenadoByLibro (libro: $libro) {
                ${recursos}
            }
        }`,
        variables: {libro},
        fetchPolicy: 'no-cache'
    }),
    getPreferidoByLibro: (client, libro, recursos) => client.query({
        query: gql`query asientoPreferidoByLibro ($libro: String!) {
            asientoPreferidoByLibro (libro: $libro) {
                ${recursos}
            }
        }`,
        variables: {libro},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, libro, nombre, codigo, provision}, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoPredisenado($id: String!, $update: JSON!){
            updateAsientoPredisenado(id: $id, update: $update){
                ${recursos}
            }
        }`,
        variables: {id, update: {libro, nombre, codigo, provision}}
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteAsientoPredisenado($id: String!){
            deleteAsientoPredisenado(id: $id){
                ${recursos}
            }
        }`,
        variables: {id}
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateAsientoPredisenado($id: String!){
            activateAsientoPredisenado(id: $id){
                ${recursos}
            }
        }`,
        variables: {id}
    })
}

export default AsientoPredisenadoModel