import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faTrash} from '@fortawesome/free-solid-svg-icons'
import {faSpinner} from '@fortawesome/fontawesome-free-solid'
import SignatureCanvas from 'react-signature-canvas'
import Slider from 'react-slick'
import {Button, Col, Modal, ModalBody, ModalFooter, Row, ModalHeader} from 'reactstrap'
import validator from 'validator'
import moment from 'moment'
import React, {Fragment, useEffect, useState, useRef} from 'react'

import bg1 from "../../assets/utils/images/originals/bg1.jpg"
import bg3 from "../../assets/utils/images/originals/bg3.jpg"
import bg2 from "../../assets/utils/images/originals/bg2.jpg"
import useAsyncSelectLabel from "../../hooks/useAsyncSelectLabel"
import MyDropzone from "../../Components/MyDropzone"
import {TipoDocumento, Reclamation, Ubigeo} from "../../Components/Models"
import {uploadImg} from "../../utils/scripts"
import {MyLabel} from "../../Components"
import {useAuth} from "../../Context"
import {useInput, useSelect} from "../../hooks"
import './sigCanvas.css'

const AppMain = () => {
    const sigCanvas = useRef({}),
        [submit, setSubmit] = useState(true),
        [show, setShow] = useState(false),
        [firma, setFirma] = useState(false),
        [pdf, setPdf] = useState(''),
        [img, setImg] = useState({}),
        {client, toast, removeAllToasts, auth} = useAuth(),
        settings = {
            dots: true, infinite: true, speed: 500, arrows: true, slidesToShow: 1, slidesToScroll: 1, fade: true,
            initialSlide: 0, autoplay: true, adaptiveHeight: true
        },
        [dates, inputDates, setDates] = useInput({
            placeholder: 'Nombres', typeState: 'datetime-local', disabled: true
        }),
        [namefull, inputNamefull, , invalidNamefull, msgNamefull] = useInput({
            placeholder: 'Nombres'
        }),
        [ubicate, inputUbicate, , invalidUbicate, , msgUbicate] = useAsyncSelectLabel({
            placeholder: 'Ubicación',
            modelo: {client, Model: Ubigeo, respuesta: 'ubigeoByParam'}
        }),
        [address, inputAddress, , invalidAddress, msgAddress] = useInput({
            placeholder: 'Dirección'
        }),
        [email, inputEmail, , invalidEmail, msgEmail] = useInput({
            placeholder: 'Correo',
            typeState: 'email'
        }),
        [phone, inputPhone, , invalidPhone, msgPhone] = useInput({
            placeholder: 'Telefono o Celular',
            typeState: 'number'
        }),
        [description, inputDescription, , invalidDescription, msgDescription] = useInput({
            placeholder: 'Detalle o Queja del Producto o Servicio',
            typeState: 'textarea'
        }),
        [nro, inputNro, , invalidNro, msgNro] = useInput({
            placeholder: 'Numero del Documento',
            typeState: 'text'
        }),
        [opcion0, selectOpcion0, , invalidOption0, setOptionsOption0, msgOption0] = useSelect({}),
        [opcion1, selectOpcion1, , invalidOption1, , msgOption1] = useSelect({
            optionsState: [
                {label: 'PRODUCTO', value: 'PRODUCTO'},
                {label: 'SERVICIO', value: 'SERVICIO'},
            ]
        }),
        [option2, selectOption2, , invalidOption2, , msgOption2] = useSelect({
            optionsState: [
                {label: 'RECLAMO', value: 'RECLAMO'},
                {label: 'QUEJA', value: 'QUEJA'},
            ]
        }),
        viewPdf = async () => {
            let datos = true
            if (namefull.length <= 0) {
                datos = false
                invalidNamefull(true)
                msgNamefull('Ingresar Nombres y Apellidos')
            }
            if (ubicate.length <= 0) {
                datos = false
                invalidUbicate(true)
                msgUbicate('Selecionar una Ciudad')
            }
            if (address.length <= 7) {
                datos = false
                invalidAddress(true)
                msgAddress('Ingresar una Dirección Completa')
            }
            if (!validator.isEmail(email)) {
                datos = false
                invalidEmail(true)
                msgEmail('Ingresar Correo Valido')
            }
            if (!validator.isMobilePhone(phone, "es-PE")) {
                datos = false
                invalidPhone(true)
                msgPhone('Ingresar un Número de Celular Válido')
            }
            if (description.length <= 10) {
                datos = false
                invalidDescription(true)
                msgDescription('Ingresar una Descripción detallada del Queja o Reclamo')
            }
            if (nro.length <= 0) {
                datos = false
                invalidNro(true)
                msgNro('Ingresar el Número del documento que Selecciono')
            }
            if (opcion0.length <= 0) {
                datos = false
                invalidOption0(true)
                msgOption0('Selecionar un Tipo de Documento')
            }
            if (opcion1.length <= 0) {
                datos = false
                invalidOption1(true)
                msgOption1('Selecione una Opción')
            }
            if (option2.length <= 0) {
                datos = false
                invalidOption2(true)
                msgOption2('Selecione una Opción')
            }

            if (sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") === 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC') {
                datos = false
                setFirma(true)
            }

            if (namefull.length > 10 && ubicate.length > 2 && address.length > 8 && validator.isEmail(email) && validator.isMobilePhone(phone, "es-PE") && description.length > 10 && nro.length > 7 && opcion0.length > 1 && opcion1.length > 1 && option2.length > 1 && sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") !== 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')
                datos = true

            let validato = await TipoDocumento.getEmail(client, email).then(({data}) => data.getGmail)

            if (!validato) {
                removeAllToasts()
                return toast.warning("No se Puede Registrar Reclamo Porque No Eres Usuario de Nuestra Plataforma", {autoClose: 5000})
            }

            if (datos) {
                toast.success(<span><FontAwesomeIcon icon={faSpinner} spin/> Generando Reporte...</span>)
                Reclamation
                    .createPdfReclamation(client, {
                        dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2,
                        firma: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
                        archivos: img.name
                    }, 'filename')
                    .then((res) => {
                        setPdf(res.data.createPdfReclamation.filename)
                        removeAllToasts()
                        setShow(true)
                    })
                    .catch(({message}) => {
                        removeAllToasts()
                        toast.error(message, {autoClose: 5000})
                    })
            } else
                toast.warning('Completar campos Requeridos', {autoClose: 5000})
        },
        save = () => {
            setShow(!show)
            Reclamation
                .createRelamation(client, {
                    dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2,
                    firma: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
                    archivos: img.name,
                    pdf
                }, 'id')
                .then(() => {
                    toast.success("Reclamo Enviado", {autoClose: 3000})
                    setSubmit(false)
                })
                .catch(({message}) => {
                    removeAllToasts()
                    toast.error(message, {autoClose: 5000})
                })
        }, onDrop = accepted => {
            uploadImg(accepted[0], 'libro', auth)
                .then(response => response.json())
                .then(({data}) => {
                    setImg({
                        preview: `${process.env.REACT_APP_API_ECOCONT}/images/libro/${data.name}`,
                        name: data.name
                    })
                })
        }

    useEffect(() => {
        if (namefull.length >= 10) invalidNamefull(false)
        if (ubicate.length >= 1) invalidUbicate(false)
        if (address.length >= 8) invalidAddress(false)
        if (validator.isEmail(email)) invalidEmail(false)
        if (validator.isMobilePhone(phone, "es-PE")) invalidPhone(false)
        if (description.length >= 10) invalidDescription(false)
        if (nro.length >= 8) invalidNro(false)
        if (opcion0.length >= 1) invalidOption0(false)
        if (opcion1.length >= 1) invalidOption1(false)
        if (option2.length >= 1) invalidOption2(false)
    }, [namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2, sigCanvas])

    useEffect(() => {
        setDates(moment().format('YYYY-MM-DDThh:mm:ss'))

        TipoDocumento
            .getAll(client, 'id, codigo, nombre')
            .then(response => {
                const {documentoslist} = response.data
                setOptionsOption0(
                    documentoslist.map(
                        ({nombre}) => ({
                            value: nombre,
                            label: nombre
                        })
                    )
                )
            })
            .catch(({message}) => {
                removeAllToasts()
                toast.error(message, {autoClose: 5000})
            })
    }, [])

    return (
        <Fragment>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="7" md="12"
                         className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                        <Col lg="11" md="11" sm="12" className="mx-auto app-login-box">
                            <div className="text-center">
                                <img src="https://app.municallao.gob.pe/secretaria/resource/images/libro.png"
                                     alt="Logo"/>
                            </div>
                            {
                                submit ? (
                                    <>
                                        <Row>
                                            <Col md={4}>
                                                <MyLabel name='FECHA' requerid/>
                                                {inputDates}
                                            </Col>
                                            <Col md={8}>
                                                <MyLabel name='NOMBRES Y APELLIDOS' requerid/>
                                                {inputNamefull}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='CIUDAD' requerid/>
                                                {inputUbicate}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='DIRECCIÓN COMPLETA' requerid/>
                                                {inputAddress}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='TIPO DE DOCUMENTO' requerid/>
                                                {selectOpcion0}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='NUMERO DEL DOCUMENTO' requerid/>
                                                {inputNro}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='CORREO ELECTRONICO' requerid/>
                                                {inputEmail}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='CELULAR' requerid/>
                                                {inputPhone}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='PRODUCTO O SERVICIO' requerid/>
                                                {selectOpcion1}
                                            </Col>
                                            <Col md={6}>
                                                <MyLabel name='RECLAMO O QUEJA' requerid/>
                                                {selectOption2}
                                            </Col>
                                            <Col md={12}>
                                                <MyLabel name='DETALLE' requerid/>
                                                {inputDescription}
                                            </Col>
                                            <Col md={7}>
                                                <MyLabel name='ADJUNTAR ARCHIVO'/>
                                                <MyDropzone {...{
                                                    onDrop: onDrop,
                                                    accept: 'image/*',
                                                    placeholder: 'Subir archivo'
                                                }} />
                                                {img.name ? (<> <strong style={{color: "red"}}>Nombre del Archivo
                                                    : </strong><span>{img.name}</span> </>) : <></>}
                                            </Col>
                                            <Col md={5}>
                                                <MyLabel name='FIRMA' requerid/>
                                                <br/>
                                                <Row>
                                                    <Col md={8}>
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor='black'
                                                            canvasProps={{width: 250, className: 'sigCanvas'}}
                                                        />
                                                        {firma ? <small
                                                            className="text-danger">{"Ingrear una Firma Válida"}</small> : <></>}
                                                    </Col>
                                                    <Col md={4}>
                                                        <div style={{textAlign: 'center'}}>
                                                            <Button color={'warning'} style={{width: '100%',}}
                                                                    onClick={() => sigCanvas.current.clear()}>
                                                                <strong>Limpiar Firma</strong>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div style={{textAlign: 'right'}}>
                                            <hr style={{marginBottom: '0px'}}/>
                                            <strong>Campos Requeridos <span style={{color: 'red'}}>*</span> </strong>
                                        </div>
                                        <br/>

                                        <Col md={12}>
                                            <Button color={'primary'} style={{width: '100%'}} onClick={() => viewPdf()}>
                                                <strong>CONTINUAR</strong>
                                            </Button>
                                        </Col>
                                        <br/>
                                    </>
                                ) : (
                                    <div style={{textAlign: 'center'}}>
                                        <h4 style={{color: '#0d4a83', fontWeight: 'bold'}}> Su Solicitud fue aceptada
                                            pronto nos contactaremos con usted.</h4>
                                        <br/>
                                        <Button color='primary' href={'/'}>
                                            <strong>Regresar al Inicio</strong>
                                        </Button>
                                    </div>
                                )
                            }

                        </Col>
                    </Col>
                    <Col lg="5" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                         style={{
                                             backgroundImage: 'url(' + bg1 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Consulta de Validez de CPE</h3>
                                        <p>
                                            Consulte la validez de boletas, facturas y sus respectivas notas
                                            electrónicas.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                         style={{
                                             backgroundImage: 'url(' + bg3 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Pago masivo de detracciones con NPD</h3>
                                        <p>
                                            Operaciones SOL para pago masivo.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg opacity-6"
                                         style={{
                                             backgroundImage: 'url(' + bg2 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Consulta DNI, RUC y EsSalud</h3>
                                        <p>
                                            Consultas de reniec, sunat y essalud sin capcha.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={show} toggle={() => show} keyboard={true} size={"lg"} backdrop={true}>
                <ModalHeader toggle={() => setShow(!show)} style={{border: '0px solid'}}
                             close={<button className="close" onClick={() => setShow(!show)}>&times;</button>}>
                    Detalle del Reclamo
                </ModalHeader>
                <ModalBody>
                    <iframe id="viewPDF"
                            src={`${process.env.REACT_APP_API_ECOCONT}/storage/libro/${pdf}#toolbar=1&amp;navpanes=0&amp;scrollbar=0`}
                            frameBorder="0" width="100%" height="600"/>
                </ModalBody>
                <ModalFooter style={{border: '0px solid'}}>
                    <Button color="warning" style={{width: '100%'}} onClick={() => setShow(!show)}>
                        <FontAwesomeIcon icon={faTrash}/> Modificar
                    </Button>
                    <Button color="primary" style={{width: '100%'}} onClick={() => save()}>
                        <FontAwesomeIcon icon={faSave}/> Enviar
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default AppMain