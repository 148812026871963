import gql from "graphql-tag"

const User = {
    create: (client, {email, nombres, a_paterno, a_materno, avatar, password, role_id, contactar, telefono}, recursos) => client.mutate({
        mutation: gql`mutation createUser(
                    $email: String!, 
                    $nombres: String!, 
                    $a_paterno: String, 
                    $a_materno: String, 
                    $avatar: String, 
                    $password: String!, 
                    $role_id: String,
                    $contactar: Boolean,
                    $telefono: String
                 ) {
                  createUser(
                    email: $email, 
                    nombres: $nombres, 
                    a_paterno: $a_paterno, 
                    a_materno: $a_materno, 
                    avatar: $avatar, 
                    password: $password,
                    role_id: $role_id,
                    contactar: $contactar,
                    telefono: $telefono
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            email, nombres, a_paterno, a_materno, avatar, password, role_id, contactar, telefono
        }
    }),
    createSub: (client, {
        id_user,name, first, last, gmail, pass, rol, adm_empresa, adm_local, module, demo, accesos, user_id
    }) => client.mutate({
        mutation: gql`mutation createSubAccount(
                $id_user: String, $name: String, $first: String, $last: String, $gmail: String, $pass: String, 
                $rol: String, $adm_empresa: JSON, $adm_local: JSON, $module: JSON, $demo: Boolean, $accesos: JSON,
                $user_id: String
             ) {
        createSubAccount(
            id_user: $id_user, name:   $name, first:  $first, last:   $last, gmail:  $gmail, pass:   $pass,
            rol:    $rol, adm_empresa: $adm_empresa, adm_local: $adm_local, module: $module, demo: $demo, 
            accesos: $accesos, user_id: $user_id
            ) {
                id
            }
        }`,
        variables: {
            id_user, name, first, last, gmail, pass, rol, adm_empresa ,adm_local, module, demo, accesos, user_id
        }
    }),
    deleteSub: (client, id) => client.mutate({
        mutation: gql`mutation deleteSubAccount(
                $id: String,
             ) {
        deleteSubAccount(
            id: $id,
            ) {
            id
          }
        }`,
        variables: {id}
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
              users {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getUser($id: String!){
                user(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {id},
        fetchPolicy: "no-cache"
    }),
    subUser: (client, id) => client.query({
        query: gql`query subUser($user_id: String!){
            subUser(user_id: $user_id) {
                id
            }
        }`,
        variables: {user_id: id},
        fetchPolicy: "no-cache"
    }),
    getActive: (client, recursos) => client.query({
        query: gql`{
              userActive {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    update: (client, {
        id, email, nombres, a_paterno, a_materno, avatar, password, role_id, accesos, telefono
    }, recursos) => client.mutate({
        mutation: gql`mutation updateUser($id: String!, $update: JSON!){
                updateUser(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
        variables: {id, update: {email, nombres, a_paterno, a_materno, avatar, password, role_id, accesos, telefono}}
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteUser($id: String!){
                deleteUser(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {id}
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateUser($id: String!){
            activateUser(id: $id){
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getByRol: (client, rol, recuros) => client.query({
        query: gql`query userbyRol($rol: String!) {
            userbyRol(rol: $rol) {
                ${recuros}
            }
        }`,
        variables: {rol},
        fetchPolicy: 'no-cache'
    }),
    createWithRol: (client, {
        email, nombres, a_paterno, a_materno, avatar, password, role_id, telefono, accesos, numero
    }, recursos) => client.mutate({
        mutation: gql`mutation createUserRole(
                    $email: String!, 
                    $nombres: String!, 
                    $a_paterno: String, 
                    $a_materno: String, 
                    $avatar: String, 
                    $password: String!, 
                    $role_id: String,
                    $telefono: String,
                    $accesos: JSON,
                    $numero: String
                 ) {
                  createUserRole(
                    email: $email, 
                    nombres: $nombres, 
                    a_paterno: $a_paterno, 
                    a_materno: $a_materno, 
                    avatar: $avatar, 
                    password: $password,
                    role_id: $role_id,
                    telefono: $telefono,
                    accesos: $accesos,
                    numero: $numero
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {email, nombres, a_paterno, a_materno, avatar, password, role_id, telefono, accesos, numero},
        fetchPolicy: 'no-cache'
    }),
    getByLocal: (client, recursos) => client.query({
        query: gql`{
            usersByLocal {
                ${recursos}
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    getByRoleId: (client, roles, recuros) => client.query({
        query: gql`query usersByRoleId($roles: JSON!) {
            usersByRoleId(roles: $roles) {
                ${recuros}
            }
        }`,
        variables: {roles},
        fetchPolicy: 'no-cache'
    }),
    getByParams: (client, params, recuros) => client.query({
        query: gql`query usersByParams($params: JSON!) {
            usersByParams(params: $params) {
                ${recuros}
            }
        }`,
        context: {timeout: 720000},
        variables: {params},
        fetchPolicy: 'no-cache'
    }),
    verificarCodigo: (client, recursos) => client.query({
        query: gql`{
              checkPendingCode {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query usuariosByParam($param: String!){
            usuariosByParam(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    })
}

export default User
