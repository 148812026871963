import gql from "graphql-tag"

const Api = {
    apiCuspp: (client, dni, recursos) => {
        return client.query({
            query: gql`query apicuspp(
                $dni: String!
            ){
                apicuspp(
                    dni: $dni
                ) {
                    ${recursos}
                }
            }`,
            variables: {dni},
            fetchPolicy: "no-cache"
        })
    },
    apiRest: (client, {path, json}, recursos) => {
        return client.query({
            query: gql`query getApiRest($path: String!, $json: JSON!){
                apirest(path: $path, json: $json) {
                    ${recursos}
                }
            }`,
            variables: {path, json},
            fetchPolicy: "no-cache"
        })
    },
    apidrive: (client, {json, path}, recursos) => {
        return client.query({
            query: gql`query apidrive($json: JSON!, $path: String!){
                apidrive(json: $json, path: $path) {
                    ${recursos}
                }
            }`,
            variables: {json, path},
            fetchPolicy: "no-cache"
        })
    },
    apiFact: (client, {path, json}, recursos) => client.query({
        query: gql`query apifact($path: String!, $json: JSON!){
                apifact(path: $path, json: $json) {
                    ${recursos}
                }
            }`,
        variables: {path, json},
        fetchPolicy: "no-cache"
    }),
    apiafp: (client, {periode}, recursos) => {
        return client.query({
            query: gql`query apiafp(
                $periode: String
            ){
                apiafp(
                    periode: $periode
                ) {
                    ${recursos}
                }
            }`,
            variables: {
                periode: periode
            },
            fetchPolicy: "no-cache"
        })
    },
    apiWhatsapp: (client, {users, message, link, file, file_image}, recursos) => client.query({
        query: gql`query apiWhatsapp($users: JSON!, $message: String!, $link: String, $file: String, $file_image: String) {
            apiWhatsapp(users: $users, message: $message, link: $link, file: $file, file_image: $file_image) {
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {users, message, link, file, file_image},
        fetchPolicy: "no-cache"
    }),
    apiEmail: (client, {
        users, tipo, modulo, promocion, message, file_pdf, file_image, valor
    }, recursos) => client.query({
        query: gql`query apiEmail($users: JSON!, $tipo: String!, $modulo: String, $promocion: String, $message: String, $file_pdf: String, $file_image: String, $valor: Float) {
            apiEmail(users: $users, tipo: $tipo, modulo: $modulo, promocion: $promocion, message: $message, file_pdf: $file_pdf, file_image: $file_image, valor: $valor) {
                ${recursos}
            }
        }`,
        context: {timeout: 720000},
        variables: {users, tipo, modulo, promocion, message, file_pdf, file_image, valor: Number(valor)},
        fetchPolicy: "no-cache"
    }),
    apiLicencias: (client, dni) => client.query({
        query: gql` query apiLicencias($dni: String){
                 apiLicencias(dni: $dni){
                     result   
              }   
        }`,
        variables: {dni}
    }),
    apiDniRucReducido: (client, documento, recursos) => {
        return client.query({
            query: gql`query apiDniRucReducido($documento: String!){
                apiDniRucReducido(documento: $documento) {
                    ${recursos}
                }
            }`,
            variables: {documento},
            fetchPolicy: "no-cache"
        })
    },
}

export default Api
