import React from "react"
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckCircle, faTrash} from "@fortawesome/free-solid-svg-icons"

import {MyLabel} from "../../../../Components"

const ModalPeriodo = ({inputPeriodo, selectLocales, isOpen, setIsOpen, seleccionarEmpresa, periodo}) => {

    return (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} backdrop={false} size="md">
            <ModalHeader toggle={() => setIsOpen(!isOpen)} style={{border: '0px solid'}}
                         close={<button className="close" onClick={() => setIsOpen(!isOpen)}>&times;</button>}>
                Seleccionar Periodo
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <MyLabel name='Locales' requerid/>
                        {selectLocales}
                    </Col>
                    <Col md={6}>
                        <MyLabel name='Periodo' requerid/>
                        {inputPeriodo}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter style={{border: '0px solid'}}>
                <Button color="danger" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faTrash}/> Cancelar
                </Button>
                <Button color="primary" onClick={seleccionarEmpresa} disabled={!(String(periodo).length > 0)}>
                    <FontAwesomeIcon icon={faCheckCircle} /> Seleccionar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPeriodo