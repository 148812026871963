import gpl from 'graphql-tag';

const PrecioProductoDetalleModel = {
    createPrecioProductoDetalle: (client, producto_id, precios) => client.mutate({
        mutation: gpl`mutation createPrecioProductoDetalle($producto_id: String!, $precios: JSON!){
            createPrecioProductoDetalle(producto_id: $producto_id, precios: $precios){
            status
            }
        }`,
        variables: { producto_id, precios }
    }),
    getPrecioProductoDetalleByProductoId: (client, producto_id, local_id) => client.query({
        query: gpl`query getPrecioProductoDetalleByProductoId ($producto_id: String!, $local_id: String){
            getPrecioProductoDetalleByProductoId(producto_id: $producto_id, local_id: $local_id){
                precios
            }
        }`,
        variables: { producto_id, local_id }, 
        fetchPolicy: 'no-cache'
    }),
    updatePrecioProductoDetalle: (client, producto_id, precios,) => client.mutate({
        mutation: gpl`mutation updatePrecioProductoDetalle($producto_id: String!, $precios: JSON!){
            updatePrecioProductoDetalle(producto_id: $producto_id, precios: $precios){
                status
            }
        }`,
        variables: { producto_id, precios}
    }),
}

export default PrecioProductoDetalleModel;