import gql from 'graphql-tag'

const CuentaFinancieraModel = {
    create: (client, {
        codigo, tipo, moneda_id, descripcion, banco_id, cuenta, detraccion, pcge_id, control
    }, recursos) => client.mutate({
        mutation: gql`mutation createCuentaFinanciera (
                        $tipo: String!,
                        $moneda_id: String!,                       
                        $descripcion: String!,                       
                        $banco_id: String,                       
                        $cuenta: String,                       
                        $detraccion: Boolean!,                       
                        $pcge_id: String!,                       
                        $codigo: String!,
                        $control: Boolean!                       
                    ) {
                    createCuentaFinanciera (
                    tipo: $tipo,
                    moneda_id: $moneda_id,
                    descripcion: $descripcion,
                    banco_id: $banco_id,
                    cuenta: $cuenta,
                    detraccion: $detraccion,
                    pcge_id: $pcge_id,
                    codigo: $codigo,
                    control: $control
                    ) {
                    ${recursos}
                    }
                    }`,
        variables: {
            codigo, tipo, moneda_id, descripcion, banco_id: banco_id !== '' ? banco_id : null, cuenta, detraccion,
            pcge_id, control
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
              cuentasFinancieras {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) =>
        client.query({
            query: gql`query cuentaFinanciera($id:String!) {
                cuentaFinanciera(id: $id) {
                           ${recursos}
                        }
                }`,
            variables: {
                id
            },
            fetchPolicy: 'no-cache'
        }),
    getByPcgeId: (client, pcge_id, recursos) =>
        client.query({
            query: gql`query cuentaFinancieraByPcgeId($pcge_id: String!) {
                cuentaFinancieraByPcgeId(pcge_id: $pcge_id) {
                           ${recursos}
                        }
                }`,
            variables: {
                pcge_id
            },
            fetchPolicy: 'no-cache'
        }),
    update: (client, {
        id, codigo, tipo, moneda_id, descripcion, banco_id, cuenta, detraccion, pcge_id, control
    }, recursos) => client.mutate({
        mutation: gql`mutation updateCuentaFinanciera($id: String!, $update: JSON!){
                updateCuentaFinanciera(id: $id, update: $update){
                ${recursos}
                }
            }`,
        variables: {
            id,
            update: {
                codigo, tipo, moneda_id, descripcion, banco_id: banco_id !== '' ? banco_id : null, cuenta, detraccion,
                pcge_id, control
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCuentaFinanciera($id: String!){
            deleteCuentaFinanciera(id: $id){
                ${recursos}
                 }
            }`,
        variables: {
            id
        }
    })
}

export default CuentaFinancieraModel