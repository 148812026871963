import gpl from 'graphql-tag';

const PreciosCategoriaModel = {
    createPreciosCategoria: (client, descripcion, valor_condicion, local_id, codigo, oferta) => client.mutate({
        mutation: gpl`mutation createPreciosCategoria($descripcion: String!, $valor_condicion: Float,
            $local_id: String!, $codigo: String!, $oferta: Boolean){
            createPreciosCategoria(descripcion: $descripcion, valor_condicion: $valor_condicion,
                local_id: $local_id, codigo: $codigo, oferta: $oferta){
                id
                descripcion
                valor_condicion,
                local_id,
                codigo,
                oferta
            }
        }`,
        variables: { descripcion, valor_condicion, local_id, codigo, oferta }
    }),
    updatePreciosCategoria: (client, id, descripcion, valor_condicion, codigo, oferta) => client.mutate({
        mutation: gpl`mutation updatePreciosCategoria($id: String!, $descripcion: String!, $valor_condicion: Float,
            $codigo: String!, $oferta: Boolean!){
                updatePreciosCategoria(id: $id, descripcion: $descripcion, valor_condicion: $valor_condicion,
                codigo: $codigo, oferta: $oferta){
                id
                descripcion
                valor_condicion
                codigo
            }
        }`,
        variables: { 
            id, 
            descripcion, 
            valor_condicion,
            codigo,
            oferta
        }
    }),
    deletePreciosCategoria: (client, id) => client.mutate({
        mutation: gpl`mutation deletePreciosCategoria($id: String!){
            deletePreciosCategoria(id: $id){
                id
            }
        }`,
        variables: { id }
    }),
    getPreciosCategoriaByLocalId: (client, local_id) => client.query({
        query: gpl`query getPreciosCategoriaByLocalId ($local_id: String!){
            getPreciosCategoriaByLocalId(local_id: $local_id){
                id
                descripcion
                valor_condicion
                codigo
                oferta
            }
        }`,
        variables: { local_id }
    }),
}

export default PreciosCategoriaModel;