import gql from 'graphql-tag'

const CpeModel = {
    ConsultarCpe: (client, cpe, recursos) => client.query({
        query: gql`query consultarCpe($cpe: JSON!){
              consultarCpe(cpe: $cpe){
              ${recursos}
              } 
        }`,
        variables: {cpe},
        fetchPolicy: 'no-cache'
    }),
    DownloadCPE: (client, recursos) => client.query({
        query: gql`{downloadsCPE{${recursos}}}`,
        fetchPolicy: 'no-cache'
    }),
    DownloadGuia: (client, type = 'dispatch') => client.query({
        query: gql` query downloadsCPE($type: String){
            downloadsCPE(type: $type){
                links 
           }
       }`,
        variables: {type},
        fetchPolicy: 'no-cache'
    }),
    ReenviarJson: (client, operacion_id, recursos) => client.query({
        query: gql`query reenviarCPE($operacion_id: String){
              reenviarCPE(operacion_id: $operacion_id){
              ${recursos}
              } 
        }`,
        variables: {operacion_id},
        fetchPolicy: 'no-cache'
    }),
    sendCpeWhatsapp: (client, {phone, operacion_id, file_name, tipo, contenedor, base}, recursos) => client.query({
        query: gql`query sendCpeWhatsapp($phone: String!, $operacion_id: String!, $file_name: String!, $tipo: String, $contenedor : Boolean, $base: String){
              sendCpeWhatsapp(phone: $phone, operacion_id: $operacion_id, file_name: $file_name, tipo: $tipo, contenedor: $contenedor, base: $base){
              ${recursos}
              } 
        }`,
        variables: {phone, operacion_id, file_name, tipo, contenedor, base},
        fetchPolicy: 'no-cache'
    })
}
export default CpeModel