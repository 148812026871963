import gql from "graphql-tag"

const Cuota = {
    getByOperacionId: (client, operacion_id, recursos) => client.query({
        query: gql`query cuotas($operacion_id: String!) {
            cuotas(operacion_id: $operacion_id) {
                ${recursos}
            }
        }`,
        variables: {operacion_id},
        fetchPolicy: 'no-cache'
    }),
    getPendientesByOperacionId: (client, operacion_id, recursos) => client.query({
        query: gql`query cuotasPendientes($operacion_id: String!) {
            cuotasPendientes(operacion_id: $operacion_id) {
                ${recursos}
            }
        }`,
        variables: {operacion_id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query cuota($id: String!) {
            cuota(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    })
}

export default Cuota
