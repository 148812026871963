import gql from "graphql-tag"

import {isToken} from "../../utils/scripts"

const Model = {
    getAll: (client, recursos) => client.query({
        query: gql`{
            ${recursos}
        }`,
        fetchPolicy: "network-only"
    }),
    getById: (client, {table, id}, recursos) => client.query({
        query: gql`query getParametrica($table: String!, $id: String!) {
            parametrica(table: $table, id: $id) {
                ${recursos}
            }
        }`,
        variables: {table, id},
        fetchPolicy: "no-cache"
    }),
    getSellerAll: (client, recursos) => client.query({
        query: gql`query {
            getSeller{
                ${recursos}
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    getUpdate: (client, recursos, id) => client.mutate({
        mutation: gql`mutation {
            ${recursos}
        }`,
        context: {headers: {isAuth: isToken(id ? id : '')}},
    }),
    excelLiquidacion: (client, {id_contract, data}, recursos) => client.query({
        query: gql`query excelLiquidacion($id_contract: String, $data: JSON) {
            excelLiquidacion(id_contract: $id_contract, data: $data) {
                ${recursos}
            }
        }`,
        variables: {id_contract, data},
        fetchPolicy: "no-cache"
    }),
    getByParam: (client, {table, param}, recursos) => client.query({
        query: gql`query parametricaByParam($table: String!, $param: String!) {
            parametricaByParam(table: $table, param: $param) {
                ${recursos}
            }
        }`,
        variables: {table, param},
        fetchPolicy: 'no-cache'
    })
}

export default Model