import gql from "graphql-tag"

const Empleado = {
    filterEmployee: (client, {send}, recursos) => client.query({
        query: gql`query filterEmployee(
            $empresa_id: String
            $id:         String
        ){
            filterEmployee(
                empresa_id: $empresa_id
                id:         $id
            ) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: send.empresa_id,
            id: send.id
        },
        fetchPolicy: "no-cache"
    }),

    listTrabajadores: (client, empresa_id, recursos) => client.query({
        query: gql`query listTrabajadores($empresa_id: String){
            listTrabajadores(empresa_id: $empresa_id) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: String(empresa_id)
        },
        fetchPolicy: "no-cache"
    }),

    listEmployee: (client, empresa_id, recursos) => client.query({
        query: gql`query listEmployee($empresa_id: String){
            listEmployee(empresa_id: $empresa_id) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: String(empresa_id)
        },
        fetchPolicy: "no-cache"
    }),

    existEmployeee: (client, {send}) => client.query({
        query: gql`query existEmployeee(
            $empresa_id: String,
            $tipo:       String,
            $numero:     String,
            $category:   String
        ){
            existEmployeee(
                empresa_id: $empresa_id,
                category:   $category,
                tipo:       $tipo,
                numero:     $numero
            ) {
                exists
            }
        }`,
        variables: {
            empresa_id: send.empresa_id,
            category: send.category,
            tipo: send.type,
            numero: send.numero
        },
        fetchPolicy: "no-cache"
    }),

    includeEmployee: (client, {planilla_id}, recursos) => client.query({
        query: gql`query includeEmployee($planilla_id: String){
            includeEmployee(planilla_id: $planilla_id) {
                ${recursos}
                listafp{
                    description
                }
            }
        }`,
        variables: {
            planilla_id: planilla_id
        },
        fetchPolicy: "no-cache"
    }),
    includeEmployeeParam: (client, {planilla_id, finit, fend, page, periodo, param}, recursos) => client.query({
        query: gql`query includeEmployeeParam ($planilla_id: String, $finit: String, $fend: String, $page: String, $periodo: String, $param: String){
                         includeEmployeeParam(planilla_id: $planilla_id, finit: $finit, fend: $fend, page: $page, periodo: $periodo, param: $param){
                    ${recursos}         
                }   
        }`,
        variables: {
            planilla_id, finit, fend, page, periodo, param
        }
    }),
    createEmployee: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation createEmployee(
                $empresa_id:    String,
                $category:      String,
                $type:          String,
                $numero:        String,
                $ap_first:      String,
                $ap_last:       String,
                $name:          String,
                $genero:        String,
                $email:         String,
                $telefono:      String,
                $foto:          String,
                $status:        String,
                $street:        String,
                $f_nacimiento:  Date,
            ){
            createEmployee(
                empresa_id:    $empresa_id,
                type:          $type,
                category:      $category,
                numero:        $numero,
                ap_first:      $ap_first,
                ap_last:       $ap_last,
                name:          $name,
                genero:        $genero,
                email:         $email,
                telefono:      $telefono,
                foto:          $foto,
                street:        $street,
                status:        $status,
                f_nacimiento:  $f_nacimiento
        ){
            ${recursos}
        }
    }`,
        variables: {
            empresa_id: send.empresa_id,
            type: send.type,
            numero: send.dni,
            category: send.category,
            ap_first: send.first,
            ap_last: send.last,
            street: send.address,
            name: send.name,
            genero: send.genero,
            email: send.email,
            telefono: send.phone,
            foto: send.foto,
            status: send.status,
            f_nacimiento: send.finit
        }
    }),

    editEmployee: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation editEmployee(
                $id:            String,
                $category:      String,
                $type:          String,
                $numero:        String,
                $ap_first:      String,
                $ap_last:       String,
                $name:          String,
                $genero:        String,
                $email:         String,
                $telefono:      String,
                $foto:          String,
                $status:        String,
                $street:        String,
                $f_nacimiento:  String
            ){
            editEmployee(
                id:            $id,
                type:          $type,
                category:      $category,
                numero:        $numero,
                ap_first:      $ap_first,
                ap_last:       $ap_last,
                name:          $name,
                genero:        $genero,
                email:         $email,
                telefono:      $telefono,
                foto:          $foto,
                street:        $street,
                status:        $status,
                f_nacimiento:  $f_nacimiento
        ){
            ${recursos}
        }
    }`,
        variables: {
            id: send.id,
            type: send.type,
            numero: send.dni,
            category: send.category,
            ap_first: send.first,
            ap_last: send.last,
            street: send.address,
            name: send.name,
            genero: send.genero,
            email: send.email,
            telefono: send.phone,
            foto: send.foto,
            status: send.status,
            f_nacimiento: send.finit
        }
    }),

    deleteEmployee: (client, {id}, recursos) => client.mutate({
        mutation: gql`mutation deleteEmployee(
                $id: String
            ){
                deleteEmployee(
                id: $id
            ){
                ${recursos}
            }
    }`,
        variables: {
            id: id
        }
    }),


    rollbackEmployee: (client, {id}, recursos) => client.mutate({
        mutation: gql`mutation rollbackEmployee(
                $id: String
            ){
            rollbackEmployee(
                id: $id
            ){
                ${recursos}
            }
    }`,
        variables: {
            id: id
        }
    }),
    getEmployeeByPeriode: (client, {excel, fechaHora}, recursos) => client.query({
        query: gql`query getEmployeeByPeriode($excel: Boolean, $fechaHora: String){
            getEmployeeByPeriode(excel: $excel, fechaHora: $fechaHora) {
                ${recursos}
            }
        }`,
        variables: {excel, fechaHora},
        fetchPolicy: "no-cache"
    }),
    trabajadorCategoria: (client, {categoria}, recursos) => client.query({
        query: gql`query trabajadoresByCategoria ($categoria: String){
           trabajadoresByCategoria(categoria: $categoria){
                ${recursos}
            }
        }`,
        variables: {categoria},
        fetchPolicy: "no-cache"
    }),
    generarPlame: (client, {categoria, codigo}, recursos) => client.query({
        query: gql`query generarEstructurasPlame($categoria: String, $codigo: String){
                    generarEstructurasPlame(categoria: $categoria, codigo: $codigo){
                        ${recursos}        
              }
            }`,
        variables: {categoria, codigo},
        fetchPolicy: "no-cache"
    }),
    selectPlame: (client, {id_empleado, code}, recursos) => client.query({
        query: gql`query selectPlameEmployee($id_empleado: String, $code:String){
        selectPlameEmployee(id_empleado: $id_empleado , code:$code){
                ${recursos}
            }
        }`,
        variables: {id_empleado, code},
        fetchPolicy: "no-cache"
    }),
    prestadoresServicios: (client, recursos) => client.query({
        query: gql`{
                  prestadoresServicios{
                        ${recursos}
                    }
                }`
        ,
        fetchPolicy: "no-cache"
    }),
    empleadosNeed: (client) => client.query({
        query: gql`{
            empleados{
                data
            }
        }`,
        fetchPolicy: "no-cache"
    }),
    permisoByEmpleado: (client, recursos) => client.query({
        query: gql`query permisoByEmpleado {
            permisoByEmpleado {
                ${recursos}        
            }
        }`,
        fetchPolicy: 'no-cache'
    })
}

export default Empleado