import gql from 'graphql-tag'

const MovimientoModel = {
    create: (client, {
        tipo_movimiento, producto_id, cantidad, valor_unitario,
        igv, precio_unitario, isc, otros, icbper, external_id
    }, recursos) => client.mutate({
        mutation: gql`mutation createMovimiento(
                        $tipo_movimiento: String!, 
                        $producto_id: String!, 
                        $cantidad: Float!, 
                        $valor_unitario: Float, 
                        $igv: Float, 
                        $precio_unitario: Float, 
                        $isc: Float, 
                        $otros: Float,
                        $icbper: Float!, 
                        $external_id: String!
                     ) {
                        createMovimiento(
                        operacion_id: $operacion_id,
                        tipo_movimiento: $tipo_movimiento,
                        producto_id: $producto_id,
                        cantidad: $cantidad,
                        valor_unitario: $valor_unitario,
                        igv: $igv,
                        precio_unitario: $precio_unitario,
                        isc: $isc,
                        otros: $otros,
                        icbper: $icbper,
                        external_id: $external_id
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            tipo_movimiento, producto_id, cantidad, valor_unitario, igv, precio_unitario, isc, otros, icbper,
            external_id
        }
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query productosbyParametro($param: String!) {
            productosbyParametro(param: $param) {
                ${recursos}
            }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    getCompraByOperacionId: (client, operacion_id, recursos) => client.query({
        query: gql`query movimientosDeCompra($operacion_id: String!) {
            movimientosDeCompra(operacion_id: $operacion_id) {
                ${recursos}
            }
        }`,
        variables: {operacion_id},
        fetchPolicy: 'no-cache'
    }),
    countByProductByMonth: (client, {producto_id, reload = false}, recursos) => client.query({
        query: gql`query countByProductByMonth($producto_id: String!, $reload: Boolean!) {
            countByProductByMonth(producto_id: $producto_id, reload: $reload) {
                ${recursos}
            }
        }`,
        variables: {producto_id, reload},
        fetchPolicy: 'no-cache'
    })
}

export default MovimientoModel
