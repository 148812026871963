import gql from 'graphql-tag'

const PagoModel = {
    create: (client, pago, recursos) => client.mutate({
        mutation: gql`mutation createPago($id: String, $operacion_id: String, $coa: String, $sunat: Boolean, $dh: String, 
            $docCantidad: Int, $dm: String, $docImporte: Float, $cuenta: String, $fecha: String, $importe: Float, 
            $glosa: String, $provisiones: JSON, $medio: String, $sustentatorio: String, $persona_id: String, 
            $isCash: Boolean, $cf: String, $importe_sunat: Float, $afecto: Float, $constancia: String, 
            $cuota_id: String, $tipo_cambio: Float, $provDc: JSON, $isDepositoDetraccion: Boolean
         ) {
            createPago(id: $id, operacion_id: $operacion_id, coa: $coa, sunat: $sunat, dh: $dh, 
                docCantidad: $docCantidad, dm: $dm, docImporte: $docImporte, cuenta: $cuenta, fecha: $fecha, 
                importe: $importe, glosa: $glosa, provisiones: $provisiones, medio: $medio, 
                sustentatorio: $sustentatorio, persona_id: $persona_id, isCash: $isCash, cf: $cf, 
                importe_sunat: $importe_sunat, afecto: $afecto, constancia: $constancia, cuota_id: $cuota_id,
                tipo_cambio: $tipo_cambio, provDc: $provDc, isDepositoDetraccion: $isDepositoDetraccion
         ) {${recursos}}
        }`,
        variables: {
            ...pago,
            importe: Number(pago.importe),
            docCantidad: parseInt(pago.docCantidad),
            docImporte: Number(pago.docImporte),
            importe_sunat: Number(pago.importe_sunat),
            tipo_cambio: Number(pago.tipo_cambio)
        }
    }),
    getAll: (client, operacion_id, recursos) => client.query({
        query: gql`query getPagos($operacion_id: String!) {
            pagos(operacion_id: $operacion_id) {
                ${recursos}
            }
        }`,
        variables: {operacion_id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getPagos($id: String!) {
            pago(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, codigo, denominacion}, recursos) => client.mutate({
        mutation: gql`mutation updatePcge($id: String!, $update: JSON!) {
            updatePcge(id: $id, update: $update) {
                ${recursos}
            }
        }`,
        variables: {
            id,
            update: {codigo, denominacion}
        }
    }),
    deleted: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletePago($id: String!) {
            deletePago(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id}
    }),
    newPagoByOperacionId: (client, operacion_id, recursos) => client.subscribe({
        query: gql`subscription newPagoByOperacionId($operacion_id: String!) {
            newPagoByOperacionId(operacion_id: $operacion_id) {
                ${recursos}
            }
        }`,
        variables: {operacion_id}
    }),
    createMasivo: (client, pagos, recursos) => client.mutate({
        mutation: gql`mutation createPagoMasivo($cuenta: String, $fecha: String, $glosa: String, $medio: String, $sustentatorio: String, $isCash: Boolean, $cf: String, $selectedRows: JSON, $pcat: String, $isCuentasPagar: Boolean, $constancia: String, $tipo_cambio: Float) {
            createPagoMasivo(cuenta: $cuenta, fecha: $fecha, glosa: $glosa, medio: $medio, sustentatorio: $sustentatorio, isCash: $isCash, cf: $cf, selectedRows: $selectedRows, pcat: $pcat, isCuentasPagar: $isCuentasPagar, constancia: $constancia, tipo_cambio: $tipo_cambio) {
                ${recursos}
            }
        }`,
        variables: {...pagos, tipo_cambio: Number(pagos.tipo_cambio)}
    })
}

export default PagoModel
