import gql from 'graphql-tag'

const VentaModel = {
    create: (client, compra, recursos) => client.mutate({
        mutation: gql`mutation createVenta(
                        $fecha_emision: String,
                        $fecha_vencimiento: String,
                        $serie: String,
                        $correlativo: String,
                        $fecha: String,
                        $serie_mod: String,
                        $correlativo_mod: String,
                        $fecha_const: String,
                        $numero_const: String,
                        $importe: Float,
                        $importe_afectacion: Float,
                        $icbper: Float,
                        $otros: Float,
                        $isc: Float,
                        $tc: Float,
                        $total: Float,
                        $comprobante: String,
                        $persona: String!,
                        $comprobante_mod: String,
                        $tipo_operacion: String,
                        $afecto: String,
                        $obligacion: String,
                        $asientos: JSON,
                        $gastos: JSON,
                        $glosa: String,
                        $movimiento: JSON,
                        $transferencia: JSON,
                        $numero_final: String,
                        $save_preferido: Boolean,
                        $preferido: JSON,
                        $porcentaje_igv: Float,
                        $amarre_cc_id: String,
                        $cps: JSON,
                        $cuotas: JSON
                     ) {
                        createVenta(
                        fecha_emision: $fecha_emision,
                        fecha_vencimiento: $fecha_vencimiento,
                        serie: $serie,
                        correlativo: $correlativo,
                        fecha: $fecha,
                        serie_mod: $serie_mod,
                        correlativo_mod: $correlativo_mod,
                        fecha_const: $fecha_const,
                        numero_const: $numero_const,
                        importe: $importe,
                        importe_afectacion: $importe_afectacion,
                        icbper: $icbper,
                        otros: $otros,
                        isc: $isc,
                        tc: $tc,
                        total: $total,
                        comprobante: $comprobante,
                        persona: $persona,
                        comprobante_mod: $comprobante_mod,
                        tipo_operacion: $tipo_operacion,
                        afecto: $afecto,
                        obligacion: $obligacion,
                        asientos: $asientos,
                        gastos: $gastos,
                        glosa: $glosa
                        movimiento: $movimiento,
                        transferencia: $transferencia,
                        numero_final: $numero_final,
                        save_preferido: $save_preferido,
                        preferido: $preferido,
                        porcentaje_igv: $porcentaje_igv,
                        amarre_cc_id: $amarre_cc_id,
                        cps: $cps,
                        cuotas: $cuotas
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            ...compra,
            fecha_vencimiento: compra.fecha_vencimiento !== '' ? compra.fecha_vencimiento : null,
            fecha_const: compra.fecha_const !== '' ? compra.fecha_const : null,
            fecha: compra.fecha !== '' ? compra.fecha : null,
            comprobante_mod: compra.comprobante_mod !== '' ? compra.comprobante_mod : null,
            icbper: Number(compra.icbper),
            otros: Number(compra.otros),
            isc: Number(compra.isc),
            tc: Number(compra.tc),
            importe: Number(compra.importe),
            importe_afectacion: Number(compra.importe_afectacion)
        }
    }),
    createOperacionVenta: (client, {
        periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id, serie, correlativo,
        fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
        tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
        importe_cdp_regimen_sunat, persona_asociado_id, movimiento, venta, codigo_libro, cuotas, jsonVenta,
        contingencias, leyendas, afecto, importe_afectacion, provisiones, cuentasFinancieras, provisionesCobro,
        provisionesCobroPercepcion, sustentatorio, tipo_operacion, medio, pdf, numero, sizePDF, gastos, guia,
        isSendApifact
    }, recursos) => client.mutate({
        mutation: gql`mutation createOperacionVenta(
                        $periodo: String,
                        $operacion_id: String,
                        $subdiario_id: String,
                        $glosa: String,
                        $glosa_referencia: String,
                        $comprobante_id: String,
                        $serie: String, 
                        $correlativo: String, 
                        $fecha_emision: String,
                        $fecha_vencimiento: String,
                        $fecha_contable: String,
                        $moneda_id: String,
                        $tipo_cambio: Float, 
                        $dato_estructurado: String, 
                        $tipo_operacion_kardex: String, 
                        $tipo_cdp_regimen_sunat: String,
                        $serie_cdp_regimen_sunat: String, 
                        $correlativo_cdp_regimen_sunat: String, 
                        $importe_cdp_regimen_sunat: Float,
                        $persona_asociado_id: String,
                        $movimiento: JSON,
                        $venta: JSON,
                        $codigo_libro: String,
                        $cuotas: JSON,
                        $jsonVenta: JSON,
                        $contingencias: JSON,
                        $afecto: String,
                        $importe_afectacion: Float
                        $provisiones: JSON
                        $provisionesCobro: JSON
                        $provisionesCobroPercepcion: JSON
                        $cuentasFinancieras: String,
                        $sustentatorio: String,
                        $tipo_operacion: String,
                        $medio: String,
                        $pdf: Boolean,
                        $numero: String,
                        $sizePDF: String,
                        $gastos: JSON,
                        $guia: String,
                        $isSendApifact: JSON
                     ) {
                        createOperacionVenta(
                        periodo: $periodo,
                        operacion_id: $operacion_id,
                        subdiario_id: $subdiario_id,
                        glosa: $glosa,
                        glosa_referencia: $glosa_referencia,
                        comprobante_id: $comprobante_id,
                        serie: $serie,
                        correlativo: $correlativo,
                        fecha_emision: $fecha_emision,
                        fecha_vencimiento: $fecha_vencimiento,
                        fecha_contable: $fecha_contable,
                        moneda_id: $moneda_id,
                        tipo_cambio: $tipo_cambio,
                        dato_estructurado: $dato_estructurado,
                        tipo_operacion_kardex: $tipo_operacion_kardex,
                        tipo_cdp_regimen_sunat: $tipo_cdp_regimen_sunat,
                        serie_cdp_regimen_sunat: $serie_cdp_regimen_sunat,
                        correlativo_cdp_regimen_sunat: $correlativo_cdp_regimen_sunat,
                        importe_cdp_regimen_sunat: $importe_cdp_regimen_sunat,
                        persona_asociado_id: $persona_asociado_id,
                        movimiento: $movimiento,
                        venta: $venta,
                        codigo_libro: $codigo_libro,
                        cuotas: $cuotas,
                        jsonVenta: $jsonVenta
                        contingencias: $contingencias,
                        afecto: $afecto,
                        importe_afectacion: $importe_afectacion
                        provisiones: $provisiones
                        cuentasFinancieras: $cuentasFinancieras
                        provisionesCobro: $provisionesCobro
                        provisionesCobroPercepcion: $provisionesCobroPercepcion
                        sustentatorio: $sustentatorio,
                        tipo_operacion: $tipo_operacion
                        medio: $medio
                        pdf: $pdf
                        numero: $numero
                        sizePDF: $sizePDF,
                        gastos: $gastos,
                        guia: $guia,
                        isSendApifact: $isSendApifact
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id, serie, correlativo,
            fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
            tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
            importe_cdp_regimen_sunat, persona_asociado_id, movimiento, venta, codigo_libro, cuotas, jsonVenta,
            contingencias, leyendas, afecto, importe_afectacion, provisiones, cuentasFinancieras, provisionesCobro,
            provisionesCobroPercepcion, sustentatorio, tipo_operacion, medio, pdf, numero, sizePDF, gastos, guia,
            isSendApifact
        }
    }),
    updateExternalId: (client, {id, external_id, estado_cpe}, recursos) => client.mutate({
        mutation: gql`mutation updateExternalid($id:String, $external_id: String, $estado_cpe: String){
            updateExternalid(id: $id, external_id: $external_id, estado_cpe: $estado_cpe){
                ${recursos}                   
            }   
        }`,
        variables: {id, external_id, estado_cpe},
        fetchPolicy: 'no-cache'
    }),
    updateExternal_idResumen: (client, {id, external_id_resumen, ticket}, recursos) => client.mutate({
        mutation: gql`mutation updateExternalidResumen($id:String, $external_id_resumen: String, $ticket: String){
            updateExternalidResumen(id: $id, external_id_resumen: $external_id_resumen,ticket: $ticket){
                ${recursos}                   
            }   
        }`,
        variables: {id, external_id_resumen, ticket},
        fetchPolicy: 'no-cache'
    }),
    updateColumnsNeed: (client, {id, valor, columna}, recursos) => client.mutate({
        mutation: gql`mutation UpdateGeneral($id: String,$valor: String, $columna: String){
            UpdateGeneral(id: $id, valor:$valor, columna: $columna){
                ${recursos}
            }
        }`,
        variables: {
            id, valor, columna
        },
        fetchPolicy: 'no-cache'
    }),
    update: (client, ventas, recursos) => client.mutate({
        mutation: gql`mutation updateVenta($id: String!, $update: JSON!){
            updateVenta(id: $id, update: $update) {
                ${recursos}
            }
        }`,
        variables: {
            id: String(ventas.id),
            update: {
                ...ventas
            }
        }
    }),
    updateOnlyVenta: (client, ventas, recursos) => client.mutate({
        mutation: gql`mutation updateOnlyVenta($id: String!, $update: JSON!){
            updateOnlyVenta(id: $id, update: $update) {
                ${recursos}
            }
        }`,
        variables: {
            id: String(ventas.id),
            update: {
                ...ventas
            }
        }
    }),
    getByOperacionId: (client, operacion_id, recursos) => client.query({
        query: gql`query ventaByOId($operacion_id: String!) {
                ventaByOId(operacion_id: $operacion_id) {
                    ${recursos}
                }
            }`,
        variables: {operacion_id},
        fetchPolicy: "no-cache"
    })
}

export default VentaModel
