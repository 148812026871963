import React, {Fragment, useEffect, useState} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {connect} from 'react-redux'
import cx from 'classnames'
import {Alert, Button, Col, Row} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSpinner} from "@fortawesome/fontawesome-free-solid"

import Notificaciones from './Components/Notificaciones'
import UserBox from './Components/UserBox'
import {decodeToken} from '../../utils/scripts'
import {useAuth} from '../../Context'
import HeaderLogo from '../AppLogo'
import {Empresa, Notificacion} from "../../Components/Models"
import ModalPeriodo from "../../Views/App/Empresa/Components/ModalPeriodo"
import {useInput, useSelect} from "../../hooks"
import {Tooltip} from "../../Components"

const Header = ({headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow}) => {
    const {client, auth, company, setCompany, toast, removeAllToasts} = useAuth()

    const [view, setView] = useState(<></>)
    const [isOpen, setIsOpen] = useState(false)
    const [notificaciones, setNotificaciones] = useState([])

    const [locales, selectLocales, setLocales, , optionsLocales] = useSelect({})
    const [periodo, inputPeriodo, setPeriodo] = useInput({
        typeState: 'month'
    })

    useEffect(() => {
        let prevTitle = document.title

        window.addEventListener('blur', () => {
            prevTitle = document.title
            // document.title = ''
        })
        window.addEventListener('focus', () => {
            document.title = prevTitle
        })
    }, [])

    useEffect(() => {
        if (!client) return
        Notificacion
            .getNotificacionesByEmpresa(client, 'id, mensaje, type, empresa_id, link')
            .then(response => {
                const {notificacionesByEmpresa} = response.data
                setNotificaciones(notificacionesByEmpresa)
            })
            .catch(({message}) => {
                toast.error(message, {autoClose: 5000})
            })
    }, [client, company.id])

    const viewEmpresa = () => {
        let modulo_id = auth.modulo_id ? auth.modulo_id : ''
        const local = company.local ? company.local : {}
        let empresaLocal = modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' && local.direccion ? local.direccion : company.razon_social
        let nombre_corto_local = local && local.nombre_corto && local.nombre_corto !== '' ? `(${local.nombre_corto})` : ''

        return (<Fragment>
            {company && company.ruc ?
                <Col sm="12" md="12" xl="12">
                    <b>{company.ruc} - {empresaLocal.toString().toUpperCase()} - <Fragment>
                        <Button id={`Tooltip-${company.id}-a`} className="btn-icon btn-icon-only p-0 m-auto"
                                color="link" onClick={() => selectEmpresa()}
                                style={{fontSize: '0.88rem', fontWeight: 'bold'}}>
                            {moment(company.periodo).locale("es").format("MMMM YYYY").toUpperCase()}
                        </Button>
                        <Tooltip id={`Tooltip-${company.id}-a`} name='Cambiar periodo' placement="right"/>
                    </Fragment> {nombre_corto_local}</b>
                </Col> : <></>
            }
        </Fragment>)
    }

    useEffect(() => {
        const {host} = window.location
        if (host.includes('itecperu')) return
        let {email, id, sub, role} = decodeToken(auth.authentication),
            Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date()

        Tawk_API.embedded = `tawk_${process.env.REACT_APP_PROPERTYID}`;

        (() => {
            let s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0]
            s1.async = true
            s1.src = `https://embed.tawk.to/${process.env.REACT_APP_PROPERTYID}/${process.env.REACT_APP_TAWKID}`
            s1.charset = 'UTF-8'
            s1.setAttribute('crossorigin', '*')
            s0.parentNode.insertBefore(s1, s0)
        })();

        window.Tawk_API = window.Tawk_API || {}

        window.Tawk_API.visitor = {
            id: id,
            name: sub,
            enterprise: company.razon_social,
            rucEmpresa: company.ruc,
            email: email,
            role: role
        }
        window.Tawk_API.onLoad = function () {
            window.Tawk_API.addTags([`Rol del Usuario : ${role}`, `Razon Social : ${company.razon_social}`, `Ruc : ${company.ruc}`], function (error) {
            });
        }

        window.Tawk_API.onLoad = function () {
            window.Tawk_API.setAttributes({
                'idUsuario': id,
                'rolUsuario': role,
                'razonEmpresa': company.razon_social,
                'rucEmpresa': company.ruc,
            }, function (error) {
            })
        }

        window.Tawk_API.onAgentLeaveChat = function () {
            window.Tawk_API.endChat()
        }
    }, [])

    useEffect(() => {
        if (!company.id) return
        setView(viewEmpresa(company))
    }, [company])

    useEffect(() => {
        if (!auth.authentication) return
        const {id} = decodeToken(auth.authentication)
        const subscribe = Empresa
            .newSelectCompany(client, id, `selectCompany`)
            .subscribe({
                next(response) {
                    const {selectCompany} = response.data.newSelectCompany
                    setCompany(selectCompany)
                },
                error(error) {
                    console.log(error)
                },
                complete(complete) {
                    console.log(complete)
                }
            })
        return () => subscribe.unsubscribe()
    }, [auth.authentication])

    const seleccionarEmpresa = () => {
        toast.success(<span><FontAwesomeIcon icon={faSpinner} spin/> Procesando...</span>)

        Empresa
            .update(client, {
                id: company.id,
                update: {periodo, activa: true, local_id: locales}
            }, 'id, ruc, razon_social, periodo, local {id, direccion, nombre_corto}, rubro, status, result')
            .then(response => {
                setIsOpen(false)
                const {updateEmpresa} = response.data
                setTimeout(() => window.location.reload(), !updateEmpresa.status ? 3000 : 0)
                removeAllToasts()
                if (!updateEmpresa.status)
                    toast.warning(`Contactar con soporte para verificar su PCGE`, {autoClose: 10000})
            })
            .catch(({message}) => {
                removeAllToasts()
                toast.error(message, {autoClose: 5000})
            })
    }

    const selectEmpresa = () => {
        toast.success(<span><FontAwesomeIcon icon={faSpinner} spin/> Procesando...</span>)

        Empresa
            .getById(client, company.id, 'id, periodo, locales {id, direccion, activo, nombre_corto}, uit')
            .then(response => {
                const {empresa} = response.data
                const {role_id} = decodeToken(auth.authentication)
                if (role_id === '203e6a2a-220c-11ec-9fa0-2bae39dbcd1e' || role_id === 'df8d2d90-3c2d-11ec-bfca-971a2d2dff9b') {
                    let newActivo = false, newId = ''
                    const locales = empresa.locales.filter(({id}) => id === decodeToken(auth.authorization).otherlocal)

                    optionsLocales(
                        locales.map(({id, direccion, activo, nombre_corto}) => {
                            newActivo = activo
                            newId = id
                            return {value: id, label: nombre_corto ? nombre_corto : direccion}
                        }))
                    setLocales(newActivo ? newId : '')
                } else {
                    let newActivo = false, newId = ''
                    optionsLocales(empresa.locales.map(({id, direccion, activo, nombre_corto}) => {
                        newActivo = activo
                        newId = id
                        return {value: id, label: nombre_corto ? nombre_corto : direccion}
                    }))
                    setLocales(newActivo ? newId : '')
                }
                setPeriodo(moment().format('YYYY-MM'))
                setIsOpen(true)

                removeAllToasts()
            })
            .catch(({message}) => {
                removeAllToasts()
                toast.error(message, {autoClose: 5000})
            })
    }

    return (
        <ReactCSSTransitionGroup component='div'
                                 className={cx('app-header', headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                                 transitionName='HeaderAnimation' transitionAppear={true} transitionAppearTimeout={1500}
                                 transitionEnter={false} transitionLeave={false}>
            <HeaderLogo/>
            <div className={cx('app-header__content', {'header-mobile-open': enableMobileMenuSmall})}>
                <div className='app-header-right'>
                    <Row className="text-center mr-0">
                        {
                            notificaciones.map(({id, mensaje, type, link}, index) => (
                                <Col sm={notificaciones.length === 2 ? 6 : 12} md={notificaciones.length === 2 ? 6 : 12}
                                     xl={notificaciones.length === 2 ? 6 : 12} key={index}
                                     className={`p-0 ${index === 1 ? 'pl-1' : ''}`}>
                                    <Alert color={['2', '3', '4', '5', '6'].includes(type) ? 'danger' : 'success'}
                                           className="m-0 p-0">
                                        <Fragment key={id}><span>{mensaje} {link ?
                                            <a href={link} rel="noreferrer" target="_blank"
                                               style={{fontWeight: 'bold'}}>
                                                AQUÍ
                                            </a> : ''}</span><br/>
                                        </Fragment>
                                    </Alert>
                                </Col>
                            ))
                        }
                        <Col sm="12" md="12" xl="12">
                            {view}
                        </Col>
                    </Row>
                    <Notificaciones/>
                    <UserBox/>
                </div>
            </div>
            <ModalPeriodo {...{inputPeriodo, selectLocales, isOpen, setIsOpen, seleccionarEmpresa, periodo}} />
        </ReactCSSTransitionGroup>
    )
}

const mapStateToProps = ({ThemeOptions}) => ({
    enableHeaderShadow: ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
