import gql from "graphql-tag"

const Home = {
    comprasVentasByMonths: (client, {include_notes}, recursos) => client.query({
        query: gql`query comprasVentasByMonths($include_notes: Boolean) {
            comprasVentasByMonths(include_notes: $include_notes) {
                ${recursos}
            } 
        }`,
        variables: {include_notes},
        fetchPolicy: 'no-cache'
    })
}

export default Home
